import { useDispatch, useSelector } from 'react-redux';

import { Button, Col, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';

import { useEffect } from 'react';
import {
  BULK_CONFIRM_BOOKING_BY_VENDOR,
  BULK_CONFIRM_BOOKING_BY_VENDOR_EMPTY,
  CONFIRM_BOOKING_BY_VENDOR,
  CONFIRM_BOOKING_BY_VENDOR_EMPTY,
} from '../../../../../../../store/actions';
import RunningCar from './../../../../../../../assets/custom/running-car.gif';

const BookingConfirm = ({ onClose, currentSelectedBooking }) => {
  const dispatch = useDispatch();

  console.log('booking confirm - ', { currentSelectedBooking });

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { confirmByVendor, confirmByVendorloading, bulkConfirmByVendor, bulkConfirmByVendorLoading } = useSelector(state => ({
    confirmByVendor: state?.ConfirmBookingByVendor?.data,
    confirmByVendorloading: state?.ConfirmBookingByVendor?.loading,

    bulkConfirmByVendor: state?.BulkBookingConfirmationByVendor?.data,
    bulkConfirmByVendorLoading: state?.BulkBookingConfirmationByVendor?.loading,
  }));

  useEffect(() => {
    if (confirmByVendor !== null) {
      dispatch({
        type: CONFIRM_BOOKING_BY_VENDOR_EMPTY,
      });
      onClose();
    }

    return () => {
      dispatch({
        type: CONFIRM_BOOKING_BY_VENDOR_EMPTY,
      });
    };
  }, [confirmByVendor]);

  const confirmBookingHandler = () => {
    if (currentSelectedBooking?.length === 1) {
      dispatch({
        type: CONFIRM_BOOKING_BY_VENDOR,
        payload: {
          body: {
            booking_id: currentSelectedBooking?.[0]?.id,
          },
        },
      });
    } else {
      dispatch({
        type: BULK_CONFIRM_BOOKING_BY_VENDOR,
        payload: {
          body: {
            booking_ids: currentSelectedBooking?.map(booking => booking?.id),
          },
        },
      });
    }
  };

  useEffect(() => {
    if (bulkConfirmByVendor !== null) {
      dispatch({
        type: BULK_CONFIRM_BOOKING_BY_VENDOR_EMPTY,
      });
      onClose();
    }

    return () => {
      dispatch({
        type: BULK_CONFIRM_BOOKING_BY_VENDOR_EMPTY,
      });
    };
  }, [bulkConfirmByVendor]);

  return (
    <Modal isOpen={true} centered>
      <ModalHeader toggle={onClose} style={{ borderBottom: '1px solid #E9EAEB', alignItems: 'flex-start' }}>
        <p className="confirm-heading">Confirm {currentSelectedBooking?.length > 1 && `(${currentSelectedBooking?.length})`}</p>
        <p className="confirm-sub-heading">Are you sure you want to confirm this booking</p>
      </ModalHeader>

      <ModalBody>
        {currentSelectedBooking?.length === 1 && (
          <Row>
            <Col md={12} className="mb-3">
              <div>
                <div className="confirm-label">Service date & time</div>
                <div className="confirm-service-date">
                  {currentSelectedBooking?.[0]?.serviceDateTime ? currentSelectedBooking?.[0]?.serviceDateTime : 'NA'}
                </div>
                <div className="running-car-container">
                  <img src={RunningCar} className="running-car" />
                </div>
              </div>
            </Col>

            <Col md={12} className="mb-3">
              <Row>
                <Col md={12}>
                  <div className="text-left">
                    <div className="confirm-label confirm-label-align-left">Pick-up Location</div>
                    <div className="confirm-body">{currentSelectedBooking?.[0]?.pickupLocation}</div>
                  </div>
                </Col>
                <Col md={12} className="mt-3">
                  <div className="text-left">
                    <div className="confirm-label confirm-label-align-left">Drop-off Location</div>
                    <div className="confirm-body">{currentSelectedBooking?.[0]?.dropLocation}</div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md={12} className="pt-3" style={{ borderTop: '1px solid #E9EAEB' }}>
              <div className="text-center d-flex justify-content-between mb-2">
                <div className="confirm-label">Customer name</div>
                <div className="confirm-body">{currentSelectedBooking?.[0]?.name}</div>
              </div>

              <div className="text-center d-flex justify-content-between mb-2">
                <div className="confirm-label">Region</div>
                <div className="confirm-body">{currentSelectedBooking?.[0]?.region}</div>
              </div>

              <div className="text-center d-flex justify-content-between mb-2">
                <div className="confirm-label">Zone</div>
                <div className="confirm-body">{currentSelectedBooking?.[0]?.zone}</div>
              </div>
            </Col>
          </Row>
        )}

        <Row>
          <Col
            md={12}
            className={`mt-3 mb-3 text-center d-flex gap-2 ${currentSelectedBooking?.length === 1 ? 'pt-4' : ''}`}
            style={{ borderTop: `${currentSelectedBooking?.length === 1} ? '1px solid #E9EAEB' : ''` }}
          >
            {currentSelectedBooking?.length === 1 && (
              <>
                {!confirmByVendorloading && (
                  <Button className="flex-grow-1" color="primary" style={{ fontSize: '14px' }} onClick={confirmBookingHandler}>
                    Yes
                  </Button>
                )}

                {confirmByVendorloading && (
                  <Button className="flex-grow-1" color="primary" style={{ fontSize: '14px' }}>
                    <Spinner className="btn-replacer-spinner" />
                  </Button>
                )}
              </>
            )}

            {currentSelectedBooking?.length > 1 && (
              <>
                {!bulkConfirmByVendorLoading && (
                  <Button className="flex-grow-1" color="primary" style={{ fontSize: '14px' }} onClick={confirmBookingHandler}>
                    Yes
                  </Button>
                )}

                {bulkConfirmByVendorLoading && (
                  <Button className="flex-grow-1" color="primary" style={{ fontSize: '14px' }}>
                    <Spinner className="btn-replacer-spinner" />
                  </Button>
                )}
              </>
            )}

            <button
              className={`btn btn-outline-primary flex-grow-1`}
              onClick={() => {
                onClose();
              }}
            >
              <span className="d-flex align-items-center">
                <span className="flex-grow-1">No</span>
              </span>
            </button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default BookingConfirm;
