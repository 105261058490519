import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import queryString from 'query-string';
import { Container } from 'reactstrap';

import moment from 'moment';
import {
  ASSIGN_CHAUFFEUR_EMPTY,
  ASSIGN_VENDOR_EMPTY,
  BOOKING_ASSIGN_TO_ME_EMPTY,
  BULK_CONFIRM_BOOKING_BY_VENDOR_EMPTY,
  CANCEL_BOOKING_BY_ADMIN_EMPTY,
  CANCEL_BOOKING_BY_VENDOR_EMPTY,
  COMPLETE_BOOKING_EMPTY,
  CONFIRM_BOOKING_BY_VENDOR_EMPTY,
  GET_BOOKING_DETAILS,
  GET_BOOKING_DETAILS_EMPTY,
  GET_BOOKINGS_LIST,
  GET_BOOKINGS_LIST_EMPTY,
  GET_CARD_SUMMARY,
  GET_DASHBOARD_CONFIGURATION,
  GET_FILTERS_LIST,
} from '../../../../../store/actions';
import { Loader } from '../../../../Common';
import BreadcrumbTrail from '../../../../Common/BreadcrumbTrail';
import { Constant } from '../../../../Helpers/constant';
import Filter from './Components/Filter/Filter';
import StatusFilter from './Components/Filter/StatusFilter';
import BookingModals from './Components/Modals';
import Summary from './Components/Summary';
import DataViews from './Components/Views';

const AirportTransferBookingV2 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const {
    skip = 1,
    limit = Constant.TABLE_PAGE_SIZE,
    startDate,
    endDate,
    name = '',
    type = '',
    appliedName = '',
    appliedType = '',
    bookingType = '',
    modification = false,
  } = queryString.parse(location.search);

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [currentPageNumber, setCurrentPageNumber] = useState(Number(skip) - 1);
  const [rowsPerPage, setRowsPerPage] = useState(Number(limit));

  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);
  const [assignedToMeFilter, setAssignedToMeFilter] = useState(false);
  const [secondaryFiltersList, setSecondaryFiltersList] = useState(null);

  const [loading, setLoading] = useState(false);
  const [currentActiveBookingAction, setCurrentActiveBookingAction] = useState(null);

  const [isMultiSelectable, setIsMultiSelectable] = useState(false);
  const [shouldHaveClearSelection, setShouldHaveClearSelection] = useState(false);
  const [currentSelectedBooking, setCurrentSelectedBooking] = useState(null);
  const [filter, setFilter] = useState({ startDate, endDate });

  const [workflowStateFilters, setWorkflowStateFilters] = useState([]);
  const [workflowDisplayName, setWorkflowDisplayName] = useState(``);
  const [appliedNameOptional, setAppliedNameOptional] = useState(``);
  const [approvalRequestType, setApprovalRequestType] = useState({
    label: 'Booking Modification',
    value: 'modify_booking',
    action: 'reject_modify_booking',
  });

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { getDashboardConfiguration, getDashboardConfigurationLoading } = useSelector((state: any) => ({
    getDashboardConfiguration: state.GetDashboardConfiguration.data,
    getDashboardConfigurationLoading: state.GetDashboardConfiguration.loading,
  }));

  const { cardSummaryCount, cardSummaryCountLoading } = useSelector((state: any) => ({
    cardSummaryCount: state.GetCardSummary.data,
    cardSummaryCountLoading: state.GetCardSummary.loading,
  }));

  const { bookingsList, bookingsListLoading, bookingDetails, bookingDetailsLoading } = useSelector((state: any) => ({
    bookingsList: state?.GetBookingsList?.data,
    bookingsListLoading: state?.GetBookingsList?.loading,

    bookingDetails: state?.GetBookingDetails?.data,
    bookingDetailsLoading: state?.GetBookingDetails?.loading,
  }));

  const {
    assignToMeResponse,
    assignChauffeurResponse,
    assignVendorResponse,
    cancelBookingByAdminResponse,
    cancelBookingByVendorResponse,
    confirmBookingByVendorResponse,
    modifyBooking,
    approvalAction,
    completeBookingResponse,
    raiseDispute,
    bulkConfirmBookingByVendorResponse,
  } = useSelector((state: any) => ({
    assignToMeResponse: state?.BookingAssignToMe?.data,
    assignChauffeurResponse: state?.AssignChauffeur?.data,
    assignVendorResponse: state?.AssignVendor?.data,
    cancelBookingByAdminResponse: state?.CancelBookingByAdmin?.data,
    cancelBookingByVendorResponse: state?.CancelBookingByVendor?.data,
    confirmBookingByVendorResponse: state?.ConfirmBookingByVendor?.data,
    modifyBooking: state?.ModifyBooking?.data,
    approvalAction: state?.ApprovalActionBooking?.data,
    completeBookingResponse: state?.CompleteBooking?.data,
    raiseDispute: state?.RaiseDispute?.data,
    bulkConfirmBookingByVendorResponse: state?.BulkBookingConfirmationByVendor?.data,
  }));

  useEffect(() => {
    if (
      assignToMeResponse !== null ||
      assignChauffeurResponse != null ||
      assignVendorResponse !== null ||
      cancelBookingByAdminResponse !== null ||
      cancelBookingByVendorResponse !== null ||
      confirmBookingByVendorResponse !== null ||
      modifyBooking !== null ||
      approvalAction !== null ||
      completeBookingResponse !== null ||
      raiseDispute !== null ||
      bulkConfirmBookingByVendorResponse !== null
    ) {
      if (isMultiSelectable) {
        setShouldHaveClearSelection(true);
        setTimeout(() => {
          setShouldHaveClearSelection(false);
        }, 1000);
      }

      dispatch({
        type: GET_BOOKINGS_LIST,
        payload: {
          urlParams: {
            page_size: limit,
            page_index: Number(skip) - 1,
            card_name: appliedName ? appliedName : name,
            start_date: startDate?.includes('T') ? startDate : moment(startDate)?.toISOString(),
            end_date: endDate?.includes('T')
              ? endDate
              : moment(new Date(`${endDate}`))
                  .add(23, 'hours')
                  .add(59, 'minutes')
                  .add(59, 'seconds')
                  .add(999, 'milliseconds')
                  ?.toISOString(),
            ...(bookingType && { approval_request_type: approvalRequestType?.value }),
            ...{ ...currentlyAppliedFilters, is_show_unassigned_bookings: assignedToMeFilter },
          },
          params: {
            card_type: appliedType ? appliedType : type,
          },
        },
      });

      dispatch({
        type: BOOKING_ASSIGN_TO_ME_EMPTY,
      });
      dispatch({
        type: ASSIGN_CHAUFFEUR_EMPTY,
      });
      dispatch({
        type: ASSIGN_VENDOR_EMPTY,
      });
      dispatch({
        type: CANCEL_BOOKING_BY_ADMIN_EMPTY,
      });
      dispatch({
        type: CANCEL_BOOKING_BY_VENDOR_EMPTY,
      });
      dispatch({
        type: CONFIRM_BOOKING_BY_VENDOR_EMPTY,
      });

      dispatch({
        type: GET_BOOKING_DETAILS_EMPTY,
      });

      dispatch({
        type: COMPLETE_BOOKING_EMPTY,
      });

      dispatch({
        type: BULK_CONFIRM_BOOKING_BY_VENDOR_EMPTY,
      });

      dispatch({
        type: GET_DASHBOARD_CONFIGURATION,
      });

      setCurrentSelectedBooking(null);
      setCurrentActiveBookingAction(null);
    }
  }, [
    assignToMeResponse,
    assignChauffeurResponse,
    assignVendorResponse,
    cancelBookingByAdminResponse,
    cancelBookingByVendorResponse,
    confirmBookingByVendorResponse,
    modifyBooking,
    approvalAction,
    completeBookingResponse,
    raiseDispute,
    bulkConfirmBookingByVendorResponse,
  ]);

  useEffect(() => {
    if (name !== `` && type !== ``) {
      dispatch({
        type: GET_BOOKINGS_LIST,
        payload: {
          urlParams: {
            page_size: limit,
            page_index: Number(skip) - 1,
            start_date: startDate ? (startDate?.includes('T') ? startDate : moment(startDate)?.toISOString()) : null,
            end_date: endDate
              ? endDate?.includes('T')
                ? endDate
                : moment(new Date(`${endDate}`))
                    .add(23, 'hours')
                    .add(59, 'minutes')
                    .add(59, 'seconds')
                    .add(999, 'milliseconds')
                    ?.toISOString()
              : null,
            card_name: appliedName ? appliedName : name,
            ...(bookingType && { approval_request_type: approvalRequestType?.value }),
            ...{ ...currentlyAppliedFilters, is_show_unassigned_bookings: assignedToMeFilter },
          },
          params: {
            card_type: appliedType ? appliedType : type,
          },
        },
      });

      getCardSummaryCounts((appliedName ? appliedName : name) ?? '', '', currentlyAppliedFilters);
    }
  }, [skip, limit, currentlyAppliedFilters, startDate, endDate, name, appliedName, approvalRequestType, workflowStateFilters, assignedToMeFilter]);

  // ********************* GET CARD SUMMARY COUNT *********************
  const getCardSummaryCounts = (name, flag, tempCurrentlyAppliedFilters) => {
    const tempStatusFilters = workflowStateFilters?.map(filter => ({ type: filter?.cardType, name: filter?.cardName }));

    let sendStatusFilters = [];
    if (cardSummaryCount === null && workflowStateFilters?.length > 0) {
      sendStatusFilters = tempStatusFilters;
    } else {
      sendStatusFilters = tempStatusFilters?.filter(statusFilter => statusFilter?.name === name);
    }

    if (flag === `GET_ALL`) {
      sendStatusFilters = tempStatusFilters;
    }

    if (workflowStateFilters?.length > 0) {
      dispatch({
        type: GET_CARD_SUMMARY,
        payload: {
          cards: sendStatusFilters ?? [],
          start_date: startDate?.includes('T') ? startDate : moment(startDate)?.toISOString(),
          end_date: endDate?.includes('T')
            ? endDate
            : moment(new Date(`${endDate}`))
                .add(23, 'hours')
                .add(59, 'minutes')
                .add(59, 'seconds')
                .add(999, 'milliseconds')
                ?.toISOString(),
          ...tempCurrentlyAppliedFilters,
        },
      });
    }
  };

  useEffect(() => {
    setCurrentPageNumber(Number(skip) - 1);
  }, [skip]);

  useEffect(() => {
    setRowsPerPage(Number(limit));
  }, [limit]);

  useEffect(() => {
    dispatch({
      type: GET_DASHBOARD_CONFIGURATION,
    });

    dispatch({
      type: GET_FILTERS_LIST,
    });
  }, []);

  useEffect(() => {
    if (getDashboardConfiguration !== null) {
      let tempWorkflowStateFilters = [];
      let workflowDisplayName = `Bookings`;
      const isAdminUser = Constant.userTypes.ADMIN_USER_V2 === Number(localStorage.getItem('userType'));

      getDashboardConfiguration?.modules?.[isAdminUser ? 'airport_transfer_dashboard' : 'airport_transfer_vendor_dashboard']?.sections?.forEach(
        (section: any, index: number) => {
          section?.cards?.forEach((card: any, index: number) => {
            if (card?.name === name) {
              tempWorkflowStateFilters = card?.workflowStateFilters ?? [];
              if (appliedName) {
                card?.workflowStateFilters?.forEach(filter => {
                  if (filter?.cardName === appliedName) {
                    workflowDisplayName = filter?.displayName;
                    setSecondaryFiltersList(filter?.secondaryFilters ?? null);
                  }
                });
              } else {
                workflowDisplayName = card?.displayName;
              }
            }
          });
        },
      );

      setWorkflowStateFilters(tempWorkflowStateFilters ?? []);

      if (tempWorkflowStateFilters?.length > 0 && !appliedName) {
        const tempActiveFilter = tempWorkflowStateFilters?.find(filter => filter?.isDefault === true)?.cardName || '';

        const tempAppliedName = tempWorkflowStateFilters?.find(filter => filter?.isDefault === true)?.cardName;
        const tempAppliedType = tempWorkflowStateFilters?.find(filter => filter?.isDefault === true)?.cardType;

        updateURLParams({ appliedName: tempAppliedName, appliedType: tempAppliedType });

        setAppliedNameOptional(appliedName || tempActiveFilter);
        setWorkflowDisplayName(workflowDisplayName);
        setSecondaryFiltersList(tempWorkflowStateFilters?.find(filter => filter?.isDefault === true)?.secondaryFilters ?? null);
      } else {
        setWorkflowDisplayName(workflowDisplayName);
      }
    }
  }, [getDashboardConfiguration]);

  useEffect(() => {
    if (currentSelectedBooking?.length === 1) {
      dispatch({
        type: GET_BOOKING_DETAILS,
        payload: {
          params: {
            booking_id: currentSelectedBooking?.[0]?.id,
          },
        },
      });
    }
  }, [currentSelectedBooking]);

  useEffect(() => {
    return () => {
      dispatch({
        type: GET_BOOKING_DETAILS_EMPTY,
      });

      dispatch({
        type: GET_BOOKINGS_LIST_EMPTY,
      });
    };
  }, []);

  const updateURLParams = newParams => {
    const currentParams = queryString.parse(location.search);

    const updatedParams = {
      ...currentParams,
      ...newParams,
    };
    const newUrl = queryString.stringify(updatedParams);
    history.push({
      pathname: location.pathname,
      search: `?${newUrl}`,
    });
  };

  const filterSubmitHandler = (filters = currentlyAppliedFilters) => {
    setCurrentlyAppliedFilters(filters);
    setCurrentActiveBookingAction(null);
    setCurrentSelectedBooking(null);
    dispatch({
      type: GET_BOOKING_DETAILS_EMPTY,
    });
    updateURLParams({ page: 1 });

    getCardSummaryCounts((appliedName ? appliedName : name) ?? '', 'GET_ALL', { ...filters });
  };

  /* ---------------------------- BREADCRUMB HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Airport Dashboard`) {
      if (Constant?.userTypes?.VENDOR_USER_V2 === Number(localStorage.getItem('userType'))) {
        history.push(`/v2/vendor-dashboard`);
      } else if (Constant?.userTypes?.ADMIN_USER_V2 === Number(localStorage.getItem('userType'))) {
        history.push(`/v2/dashboard`);
      }
    }
  };

  const updateFilter = (startDate, endDate) => {
    setFilter({ startDate, endDate });
    updateURLParams({ startDate, endDate });
  };

  const approvalRequestTypeHandler = data => {
    setApprovalRequestType(data);
    setCurrentSelectedBooking(null);
  };

  useEffect(() => {
    if (bookingsListLoading || bookingDetailsLoading || getDashboardConfigurationLoading || cardSummaryCountLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [bookingsListLoading, bookingDetailsLoading, getDashboardConfigurationLoading, cardSummaryCountLoading]);

  return (
    <div className="page-content">
      {loading && <Loader zIndex={true} />}

      {
        <BookingModals
          currentSelectedBooking={currentSelectedBooking}
          currentActiveBookingAction={currentActiveBookingAction}
          setCurrentActiveBookingAction={setCurrentActiveBookingAction}
          isMultiSelectable={isMultiSelectable}
          approvalRequestType={approvalRequestType}
        />
      }

      <Container fluid className="position-relative">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex gap-1 align-items-center">
            <BreadcrumbTrail
              title={Constant?.userTypes?.VENDOR_USER_V2 === parseInt(localStorage.getItem('userType')) ? `My Bookings` : `${workflowDisplayName}`}
              navigationby={breadcrumNavigationHandler}
              navigation={[`Airport Dashboard`, `${workflowDisplayName || ''}`]}
              filter={filter}
              updateFilter={updateFilter}
              isShowDate={Constant.userTypes.VENDOR_USER_V2 === Number(localStorage.getItem('userType'))}
            />
          </div>

          <Filter
            filterHandler={filterSubmitHandler}
            bookingType={bookingType}
            approvalRequestTypeHandler={approvalRequestTypeHandler}
            modification={modification}
          />
        </div>

        <>
          <StatusFilter
            statusFilters={workflowStateFilters}
            appliedStatusFilter={appliedName || appliedNameOptional}
            updateURLParams={updateURLParams}
            setCurrentSelectedBooking={setCurrentSelectedBooking}
            setCurrentActiveBookingAction={setCurrentActiveBookingAction}
            setWorkflowDisplayName={setWorkflowDisplayName}
            cardSummaryCount={cardSummaryCount}
            setShouldHaveClearSelection={setShouldHaveClearSelection}
            setSecondaryFiltersList={setSecondaryFiltersList}
            setAssignedToMeFilter={setAssignedToMeFilter}
          />

          <DataViews
            data={bookingsList}
            updateURLParams={updateURLParams}
            skip={currentPageNumber}
            limit={rowsPerPage}
            selectedBooking={currentSelectedBooking}
            setCurrentSelectedBooking={setCurrentSelectedBooking}
            setCurrentActiveBookingAction={setCurrentActiveBookingAction}
            titleText={workflowDisplayName}
            appliedName={appliedName?.toString()}
            isMultiSelectable={isMultiSelectable}
            setIsMultiSelectable={setIsMultiSelectable}
            shouldHaveClearSelection={shouldHaveClearSelection}
            setShouldHaveClearSelection={setShouldHaveClearSelection}
            filterSubmitHandler={filterSubmitHandler}
            currentlyAppliedFilters={currentlyAppliedFilters}
            assignedToMeFilter={assignedToMeFilter}
            setAssignedToMeFilter={setAssignedToMeFilter}
            secondaryFiltersList={secondaryFiltersList}
          />

          {currentSelectedBooking?.length === 1 && bookingDetails && (
            <Summary
              details={bookingDetails}
              currentSelectedBooking={currentSelectedBooking?.[0]}
              setCurrentSelectedBooking={setCurrentSelectedBooking}
              setCurrentActiveBookingAction={setCurrentActiveBookingAction}
            />
          )}
        </>
      </Container>
    </div>
  );
};

export default AirportTransferBookingV2;
