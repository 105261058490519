import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Constant } from '../../../Helpers/constant';
import { GET_DOWNLOAD_DUMP_ADMIN_V2 } from '../../../../store/actions';
import { Loader } from '../../../Common';
import { Button, Card, CardBody, CardHeader, Container } from 'reactstrap';
import BreadcrumbTrail from '../../../Common/BreadcrumbTrail';
import TabularView from './Components/Views/TabularView';

const DownloadReportsV2 = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { skip = 1, limit = Constant.TABLE_PAGE_SIZE } = queryString.parse(location.search);

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [currentPageNumber, setCurrentPageNumber] = useState(Number(skip) - 1);
  const [rowsPerPage, setRowsPerPage] = useState(Number(limit));
  const [loading, setLoading] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { downloadDumpList, downloadDumpListLoading } = useSelector((state: any) => ({
    downloadDumpList: state.GetDownloadDumpList.data,
    downloadDumpListLoading: state.GetDownloadDumpList.loading,
  }));

  useEffect(() => {
    dispatch({
      type: GET_DOWNLOAD_DUMP_ADMIN_V2,
      payload: {
        urlParams: {
          page_size: limit,
          page_index: Number(skip) - 1,
        },
      },
    });
  }, [skip, limit, isRefresh]);

  useEffect(() => {
    setCurrentPageNumber(Number(skip) - 1);
  }, [skip]);

  useEffect(() => {
    setRowsPerPage(Number(limit));
  }, [limit]);

  const updateURLParams = newParams => {
    const currentParams = queryString.parse(location.search);

    const updatedParams = {
      ...currentParams,
      ...newParams,
    };
    const newUrl = queryString.stringify(updatedParams);
    history.push({
      pathname: location.pathname,
      search: `?${newUrl}`,
    });
  };

  /* ---------------------------- BREADCRUMB HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/v2/vendor-dashboard`);
    } else if (title === `Download Reports`) {
      console.log('Download Reports');
    }
  };

  useEffect(() => {
    if (downloadDumpListLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [downloadDumpListLoading]);

  const tableActionHandler = (label, data) => {
    console.log('tableActionHandler', label, data);
  };

  const handleRowsPerPageChange = newRowsPerPage => {
    updateURLParams({ skip: 1, limit: newRowsPerPage });
  };

  const paginationHandler = pageNumber => {
    updateURLParams({ skip: pageNumber + 1, limit: limit });
  };

  return (
    <div className="page-content">
      {loading && <Loader zIndex={true} />}

      <Container fluid className="position-relative">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex gap-1 align-items-center">
            <BreadcrumbTrail title={`Download Reports`} navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Download Reports`]} />
          </div>
        </div>

        <Card>
          <CardHeader className="d-flex justify-content-between align-items-center">
            <h4>Download Reports</h4>
            <Button className="refresh-btn btn btn-success mx-2" onClick={() => setIsRefresh(!isRefresh)}>
              Refresh&nbsp;
              <span>
                <i className="ri-refresh-line"></i>
              </span>
            </Button>
          </CardHeader>

          <CardBody>
            <TabularView
              data={downloadDumpList?.results}
              totalCount={downloadDumpList?.totalCount}
              skip={currentPageNumber}
              limit={rowsPerPage}
              handleRowsPerPageChange={handleRowsPerPageChange}
              handlePageChange={paginationHandler}
              actionBtnHandler={tableActionHandler}
              isTableDisplay={true}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default DownloadReportsV2;
