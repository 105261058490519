import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { Card, CardBody, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { GET_CARD_SUMMARY, GET_DASHBOARD_CONFIGURATION } from '../../../../store/actions';
import { DonutChart, GeoChart, Loader } from '../../../Common';
import BreadcrumbTrail from '../../../Common/BreadcrumbTrail';
import StatusCard from './Components/StatusCard';
import TimeSlot from './Components/TimeSlot';
import DownloadModal from './Components/Modals/DownloadModal';

const filterOptions = [
  { value: 'Date Wise', label: 'Date Wise' },
  { value: 'Global', label: 'Global' },
];

const slaData = [
  { id: 1, count: 560, status: 'No Change in Booking' },
  { id: 2, count: 450, status: 'Increased by 10' },
  { id: 3, count: 600, status: 'Decreased by 5' },
  { id: 4, count: 500, status: 'No Change in Booking' },
  { id: 5, count: 520, status: 'Increased by 3' },
];

const timeSlots = [
  { id: '12am-6am', label: '12 AM - 6 AM', startHour: 0, endHour: 6 },
  { id: '6am-12pm', label: '6 AM - 12 PM', startHour: 6, endHour: 12 },
  { id: '12pm-6pm', label: '12 PM - 6 PM', startHour: 12, endHour: 18 },
  { id: '6pm-12am', label: '6 PM - 12 AM', startHour: 18, endHour: 24 },
];

interface RootState {
  GetDashboardConfiguration?: {
    data?: any;
    loading?: boolean;
  };
  GetCardSummary?: {
    data?: any;
    loading?: boolean;
  };
}

const VendorDashboardV2: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [filter, setFilter] = useState(filterOptions[0]);
  const [activeTab, setActiveTab] = useState('today');
  const [activeTimeSlot, setActiveTimeSlot] = useState('');
  const [data, setData] = useState({ timelineWiseBooking: null });
  const [timelineCard, setTimelineCard] = useState([]);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState({ startDate: '', endDate: '' });
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);

  const { getDashboardConfiguration, getDashboardConfigurationLoading } = useSelector((state: RootState) => ({
    getDashboardConfiguration: state.GetDashboardConfiguration.data,
    getDashboardConfigurationLoading: state.GetDashboardConfiguration.loading,
  }));

  const { cardData, cardDataLoading } = useSelector((state: RootState) => ({
    cardData: state.GetCardSummary.data,
    cardDataLoading: state.GetCardSummary.loading,
  }));

  useEffect(() => {
    dispatch({
      type: GET_DASHBOARD_CONFIGURATION,
    });
  }, []);

  useEffect(() => {
    if (getDashboardConfiguration !== null) {
      console.log('getDashboardConfiguration', getDashboardConfiguration);
      const defaultVisibleCards = getDashboardConfiguration?.modules?.airport_transfer_vendor_dashboard?.sections
        ?.filter((data: any) => data?.defaultVisibility === true)
        ?.flatMap((section: any) => section?.cards || []);
      console.log('defaultVisibleCards', defaultVisibleCards);
      setTimelineCard(defaultVisibleCards);
    }
  }, [getDashboardConfiguration]);

  const getCurrentTimelineBookings = () => {
    const timeSlotData = getCurrentTimeSlot();
    setDate({ startDate: timeSlotData.startDate, endDate: timeSlotData.endDate });
    if (timeSlotData) {
      setActiveTimeSlot(timeSlotData.id);
      if (timelineCard?.length > 0) {
        dispatch({
          type: GET_CARD_SUMMARY,
          payload: {
            cards: timelineCard,
            start_date: timeSlotData?.startDate || '',
            end_date: timeSlotData.endDate || '',
          },
        });
      }
    }
  };

  useEffect(() => {
    getCurrentTimelineBookings();
  }, [timelineCard]);

  useEffect(() => {
    if (getDashboardConfigurationLoading || cardDataLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [getDashboardConfigurationLoading, cardDataLoading]);

  const tabs = [
    { id: 'past', label: 'Past' },
    { id: 'yesterday', label: 'Yesterday' },
    { id: 'today', label: 'Today' },
    { id: 'tomorrow', label: 'Tomorrow' },
    ...Array.from({ length: 5 }, (_, i) => {
      const date = moment().add(i + 2, 'days');
      return { id: date.format('DMM'), label: date.format('DD MMM') };
    }),
  ];

  console.log('Tabs', tabs);

  // Function to determine the current time slot
  const getCurrentTimeSlot = () => {
    const currentHour = moment().hour();
    const currentDate = moment().format('YYYY-MM-DD');

    for (const slot of timeSlots) {
      if (currentHour >= slot.startHour && currentHour < slot.endHour) {
        const startDate = moment(`${currentDate} ${slot.startHour}:00`).toISOString();
        const endDate = moment(`${currentDate} ${slot.endHour}:00`).toISOString();
        return { id: slot.id, startDate, endDate };
      }
    }

    return null;
  };

  const getSelectedDate = (activeTab: string) => {
    console.log('activeTab', activeTab);
    if (activeTab === 'past') {
      return moment().subtract(2, 'days');
    }
    if (activeTab === 'yesterday') {
      return moment().subtract(1, 'days');
    }
    if (activeTab === 'today') {
      return moment();
    }
    if (activeTab === 'tomorrow') {
      return moment().add(1, 'days');
    }
    const selectedTab = tabs.find(tab => tab.id === activeTab);

    if (!selectedTab) {
      console.error('Invalid activeTab:', activeTab);
      return null;
    }

    // Extract day and month from activeTab
    const day = activeTab.substring(0, activeTab.length - 2); // Extract the day part
    const month = activeTab.substring(activeTab.length - 2); // Extract the month part

    console.log('day', day);
    console.log('month', month);

    // Create a formatted date
    const formattedDate = moment(`${day}-${month}-${moment().year()}`, 'DD-MM-YYYY');

    return formattedDate;
  };

  const daySelectHandler = (id: string) => {
    setActiveTab(id);
    const selectedSlot = timeSlots.find(slot => slot.id === activeTimeSlot);
    const selectedDate = getSelectedDate(id);
    if (selectedSlot && selectedDate) {
      const startDate = selectedDate.set('hour', selectedSlot.startHour).set('minute', 0).set('second', 0).toISOString();
      const endDate = selectedDate.set('hour', selectedSlot.endHour).set('minute', 0).set('second', 0).toISOString();
      if (timelineCard?.length > 0) {
        dispatch({
          type: GET_CARD_SUMMARY,
          payload: {
            cards: timelineCard,
            start_date: startDate || '',
            end_date: endDate || '',
          },
        });
      }

      setDate({ startDate: startDate, endDate: endDate });
    }
  };

  const timeSelectHandler = (id: string) => {
    setActiveTimeSlot(id);
    const selectedSlot = timeSlots.find(slot => slot.id === id);
    const selectedDate = getSelectedDate(activeTab);
    if (selectedSlot && selectedDate) {
      const startDate = selectedDate.set('hour', selectedSlot.startHour).set('minute', 0).set('second', 0).toISOString();
      const endDate = selectedDate.set('hour', selectedSlot.endHour).set('minute', 0).set('second', 0).toISOString();
      if (timelineCard?.length > 0) {
        dispatch({
          type: GET_CARD_SUMMARY,
          payload: {
            cards: timelineCard,
            start_date: startDate || '',
            end_date: endDate || '',
          },
        });
      }

      setDate({ startDate: startDate, endDate: endDate });
    }
  };

  return (
    <div className="page-content">
      <Container fluid className="position-relative">
        {loading && <Loader />}
        {isDownloadModalOpen && (
          <DownloadModal
            onClose={() => {
              setIsDownloadModalOpen(false);
            }}
          />
        )}

        <div className="d-flex align-items-center justify-content-between">
          <BreadcrumbTrail
            title="Dashboard"
            navigationby={title => title === 'Dashboard' && history.push('/v2/vendor-dashboard')}
            navigation={['Dashboard']}
          />

          <div className="d-flex align-items-center justify-content-center gap-2">
            <span
              className="btn btn-primary"
              onClick={() => {
                setIsDownloadModalOpen(true);
              }}
            >
              <i className="ri-download-2-line text-white align-middle"></i>
            </span>
            <Select
              options={filterOptions}
              value={filter}
              styles={{ control: provided => ({ ...provided, width: '200px' }) }}
              onChange={data => {
                setFilter(data);
                setDate(null);
                if (data?.value === 'Date Wise') {
                  getCurrentTimelineBookings();
                } else {
                  dispatch({
                    type: GET_CARD_SUMMARY,
                    payload: {
                      cards: timelineCard,
                    },
                  });
                }
              }}
            />
          </div>
        </div>
      </Container>

      <Card>
        <CardBody>
          {filter.value === 'Date Wise' ? (
            <>
              <Nav tabs className="nav-tabs-custom nav-success nav-justified mb-3">
                {tabs.map(tab => (
                  <NavItem key={tab.id}>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={`${classNames({ active: activeTab === tab.id })} fs-14`}
                      onClick={() => daySelectHandler(tab.id)}
                    >
                      {tab.label}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>

              <TabContent activeTab={activeTab} className="text-muted">
                {tabs.map(tab => (
                  <TabPane key={tab.id} tabId={tab.id}>
                    <TimeSlot timeSlots={timeSlots} activeTimeSlot={activeTimeSlot} onTimeSlotSelect={timeSelectHandler} />
                    <div className="sla-container mt-4 p-1">
                      <div className="status-vendor-scroll">
                        {timelineCard?.length > 0 &&
                          timelineCard?.map((timelineBooking, index) => (
                            <StatusCard key={index} timelineBooking={timelineBooking} date={date} cardData={cardData} />
                          ))}
                      </div>
                    </div>
                  </TabPane>
                ))}
              </TabContent>
            </>
          ) : (
            <div className="sla-container mt-4 p-1">
              <div className="status-vendor-scroll">
                {timelineCard?.length > 0 &&
                  timelineCard?.map((timelineBooking, index) => (
                    <StatusCard key={index} timelineBooking={timelineBooking} date={date} cardData={cardData} />
                  ))}{' '}
              </div>
            </div>
          )}
        </CardBody>
      </Card>

      {/* <Row>
        {data?.pickAndDrops && (
          <DonutChart data={data?.pickAndDrops?.data} lastUpdatedAt={data?.pickAndDrops?.lastUpdatedAt} title={'Pickups vs Drops'} />
        )}
        {data?.regionWiseChart && <GeoChart data={data?.regionWiseChart?.data} title={'Region Wise Bookings'} />}
      </Row> */}
    </div>
  );
};

export default VendorDashboardV2;
