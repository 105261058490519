import { all, fork } from 'redux-saga/effects';

import assignVendorRemarksSaga from './Bookings/AirportTransfer/AssignVendorRemarksList/saga';
import cancelBookingByVendorSaga from './Bookings/AirportTransfer/CancelBookingByVendor/saga';
import assignVendorSaga from './Bookings/AirportTransfer/AssignVendor/saga';
import createChauffeurSaga from './Bookings/AirportTransfer/CreateChauffeur/saga';
import confirmBookingByVendorSaga from './Bookings/AirportTransfer/ConfirmBookingByVendor/saga';
import bookingAssignToMeSaga from './Bookings/AirportTransfer/AssignToMe/saga';
import getBookingsListSaga from './Bookings/AirportTransfer/GetBookingsList/saga';
import getCarsListOfVendorSaga from './Bookings/AirportTransfer/GetCarsListOfVendor/saga';
import assignChauffeurSaga from './Bookings/AirportTransfer/AssignChauffeur/saga';
import getFilterValuesSaga from './Bookings/AirportTransfer/GetFilterValues/saga';
import getFiltersListSaga from './Bookings/AirportTransfer/GetFiltersList/saga';
import cancellationRemarksForVendorSaga from './Bookings/AirportTransfer/CancellationRemarksForVendor/saga';
import getVendorsListSaga from './Bookings/AirportTransfer/GetVendorsList/saga';
import getChauffeursListSaga from './Bookings/AirportTransfer/GetChauffeursList/saga';
import getBookingDetailsSaga from './Bookings/AirportTransfer/GetBookingDetails/saga';
import watchBookingTypeSaga from './BookingType/saga';
import watchV2DashboardSaga from './Dashboard/saga';
import watchUserRolesSaga from './UserRole/saga';
import cancelBookingByAdminSaga from './Bookings/AirportTransfer/CancelBookingByAdmin/saga';
import getBookingTypeForGlobalSearchSaga from './Bookings/GetBookingType/saga';
import getBookingGlobalSearchSaga from './Bookings/GetGlobalSearchBooking/saga';
import showModifyFieldsSaga from './Bookings/AirportTransfer/ModifyFields/saga';
import modifyBookingSaga from './Bookings/AirportTransfer/ModifyBooking/saga';
import approvalActionBookingSaga from './Bookings/AirportTransfer/ApprovalAction/saga';
import remarkStatusDetailsSaga from './DynamicReason/GetRemarkStatus/saga';
import getActionByStatusSaga from './DynamicReason/GetActionByStatus/saga';
import getReasonByActionsSaga from './DynamicReason/GetReason/saga';
import addReasonSaga from './DynamicReason/AddReason/saga';
import updateReasonSaga from './DynamicReason/UpdateReason/saga';
import completeBookingSaga from './Bookings/AirportTransfer/CompleteBooking/saga';
import raiseDisputeSaga from './Bookings/AirportTransfer/RaiseDispute/saga';
import bookingDumpTypeListSaga from './GetBookingDumpTypeList/saga';
import postDownloadDumpAdminV2Saga from './DownloadDump/saga';
import getDownlaodDumpListSaga from './GetDownloadDumpList/saga';
import bulkBookingConfirmationByVendorSaga from './Bookings/AirportTransfer/BulkBookingConfirm/saga';

export function* bookingsSagas() {
  yield all([
    fork(assignVendorRemarksSaga),
    fork(cancelBookingByVendorSaga),
    fork(assignVendorSaga),
    fork(createChauffeurSaga),
    fork(confirmBookingByVendorSaga),
    fork(bookingAssignToMeSaga),
    fork(getBookingsListSaga),
    fork(getCarsListOfVendorSaga),
    fork(assignChauffeurSaga),
    fork(getFilterValuesSaga),
    fork(getFiltersListSaga),
    fork(cancellationRemarksForVendorSaga),
    fork(getVendorsListSaga),
    fork(getChauffeursListSaga),
    fork(getBookingDetailsSaga),
    fork(watchBookingTypeSaga),
    fork(watchV2DashboardSaga),
    fork(watchUserRolesSaga),
    fork(cancelBookingByAdminSaga),
    fork(getBookingTypeForGlobalSearchSaga),
    fork(getBookingGlobalSearchSaga),
    fork(showModifyFieldsSaga),
    fork(modifyBookingSaga),
    fork(approvalActionBookingSaga),
    fork(remarkStatusDetailsSaga),
    fork(getActionByStatusSaga),
    fork(getReasonByActionsSaga),
    fork(addReasonSaga),
    fork(updateReasonSaga),
    fork(completeBookingSaga),
    fork(raiseDisputeSaga),
    fork(bookingDumpTypeListSaga),
    fork(postDownloadDumpAdminV2Saga),
    fork(getDownlaodDumpListSaga),
    fork(bulkBookingConfirmationByVendorSaga),
  ]);
}

export default bookingsSagas;
