import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import HotTimeClock from './../../../../../../../assets/custom/hot-time-clock.gif';
import SufficientTimeClock from './../../../../../../../assets/custom/sufficient-time-clock.gif';
import WarningTimeClock from './../../../../../../../assets/custom/warning-time-clock.gif';

const CardView = ({ data, isMultiSelectable, currentSelectedBooking, setCurrentSelectedBooking, setCurrentActiveBookingAction }) => {
  return (
    <div className="horizontal-scrollor">
      <div className="booking-data-card-container">
        {data?.map((result, index) => (
          <>
            <Card className={`booking-card ${currentSelectedBooking?.[0]?.bookingId === result?.bookingId ? 'active' : ''}`} key={index}>
              <CardHeader>
                <div className="booking-card-header">
                  <div className="booking-id-details">
                    <p>
                      Booking Id -{' '}
                      <span
                        className=""
                        style={{ color: result?.rowColorCode ? result?.rowColorCode : '#3577f1' }}
                        onClick={() => setCurrentSelectedBooking([{ ...result }])}
                      >
                        {result?.bookingId || ''}
                      </span>
                    </p>
                  </div>
                  <div
                    className="booking-tat-details"
                    style={{
                      backgroundColor: `${result?.tat?.color || 'white'}`,
                    }}
                  >
                    {result?.tat?.color === '#FCE2E2' && <img src={HotTimeClock} className="remaining-clock-time" />}
                    {result?.tat?.color === '#FFF5E7' && <img src={WarningTimeClock} className="remaining-clock-time" />}
                    {result?.tat?.color === '#E5EBFF' && <img src={SufficientTimeClock} className="remaining-clock-time" />}

                    <p> {`${result?.tat?.value} ${result?.tat?.unit} Remaining`}</p>
                  </div>
                </div>
              </CardHeader>

              <CardBody className="booking-card-body">
                <Row>
                  <Col md={6} className="mb-3">
                    <div className="booking-details-heading">Customer Name</div>
                    <div className="booking-details-data">{result?.name || `NA`}</div>
                  </Col>

                  <Col md={6} className="mb-3">
                    <div className="booking-details-heading">Service Date & Time</div>
                    <div className="booking-details-data">{result?.serviceDateTime || `NA`}</div>
                  </Col>

                  <Col md={6} className="mb-3">
                    <div className="booking-details-heading">Created At</div>
                    <div className="booking-details-data">{result?.bookingCreatedAt || `NA`}</div>
                  </Col>
                  <Col md={6} className="mb-3">
                    <div className="booking-details-heading">Region</div>
                    <div className="booking-details-data">{result?.region || `NA`}</div>
                  </Col>
                  <Col md={6} className="mb-3">
                    <div className="booking-details-heading">Zone</div>
                    <div className="booking-details-data">{result?.zone || `NA`}</div>
                  </Col>

                  {result?.chauffeurName && result?.chauffeurName !== '' && (
                    <Col md={6} className="mb-3">
                      <div className="booking-details-heading">Chauffeur Name</div>
                      <div className="booking-details-data">{result?.chauffeurName || `NA`}</div>
                    </Col>
                  )}

                  {result?.chauffeurMobile && result?.chauffeurMobile !== '' && (
                    <Col md={6} className="mb-3">
                      <div className="booking-details-heading">Chauffeur Mobile</div>
                      <div className="booking-details-data">{result?.chauffeurMobile || `NA`}</div>
                    </Col>
                  )}

                  {result?.carNumber && result?.carNumber !== '' && (
                    <Col md={6} className="mb-3">
                      <div className="booking-details-heading">Car Number</div>
                      <div className="booking-details-data">{result?.carNumber || `NA`}</div>
                    </Col>
                  )}

                  {result?.carType && result?.carType !== '' && (
                    <Col md={6} className="mb-3">
                      <div className="booking-details-heading">Car Type</div>
                      <div className="booking-details-data">{result?.carType || `NA`}</div>
                    </Col>
                  )}

                  <Col md={12} className="mb-3">
                    <div className="booking-details-heading">Pick Up Location</div>
                    <div className="booking-details-data">{result?.pickupLocation || `NA`}</div>
                  </Col>

                  <Col md={12} className="mb-3">
                    <div className="booking-details-heading">Drop Location</div>
                    <div className="booking-details-data">{result?.dropLocation || `NA`}</div>
                  </Col>

                  {result?.vendorBookingDeclinedAt && result?.vendorBookingDeclinedAt !== '' && (
                    <>
                      <Col md={12} className="text-center mb-2 mt-3">
                        <div className="danger-badge-label-container">
                          Booking declined - {result?.vendorBookingDeclinedAt ? result?.vendorBookingDeclinedAt : `NA`}
                        </div>
                      </Col>

                      <Col md={12} className="mb-2">
                        <div className="danger-badge-reason">
                          <span className="danger-badge-heading">Reason</span>{' '}
                          <span className="danger-badge-text">- {result?.vendorDeclinedReason || `NA`}</span>
                        </div>
                      </Col>
                    </>
                  )}

                  {result?.disputeRiseOn && result?.disputeRiseOn !== '' && (
                    <>
                      <Col md={12} className="text-center mb-2 mt-3">
                        <div className="danger-badge-label-container">Dispute Raise on -{result?.disputeRiseOn ? result?.disputeRiseOn : `NA`}</div>
                      </Col>

                      <Col md={12} className="mb-2">
                        <div className="danger-badge-reason">
                          <span className="danger-badge-heading">Reason</span>{' '}
                          <span className="danger-badge-text">- {result?.disputeReason || `NA`}</span>
                        </div>
                      </Col>
                    </>
                  )}

                  {result?.cancelledAt && result?.cancelledAt !== '' && (
                    <>
                      <Col md={12} className="text-center mb-2 mt-3">
                        <div className="danger-badge-label-container">Booking cancelled -{result?.cancelledAt ? result?.cancelledAt : `NA`}</div>
                      </Col>

                      <Col md={12} className="mb-2">
                        <div className="danger-badge-reason">
                          <span className="danger-badge-heading">Reason</span>{' '}
                          <span className="danger-badge-text">-{result?.cancelledReason || `NA`}</span>
                        </div>
                      </Col>
                    </>
                  )}
                </Row>

                <Row>
                  <Col md={12} className="text-center mt-3">
                    <div className="d-flex gap-2 flex-wrap justify-content-center">
                      {result?.actions?.includes(`assign_to_me`) && (
                        <Button
                          className="customized-btn"
                          color="primary"
                          onClick={() => {
                            setCurrentSelectedBooking([{ ...result }]);
                            setCurrentActiveBookingAction(`assign_to_me`);
                          }}
                        >
                          Assign to Me
                        </Button>
                      )}

                      {result?.actions?.includes(`assign_to_vendor`) && (
                        <Button
                          className="customized-btn"
                          color="success"
                          onClick={() => {
                            setCurrentSelectedBooking([{ ...result }]);
                            setCurrentActiveBookingAction(`assign_to_vendor`);
                          }}
                        >
                          Assign to Vendor
                        </Button>
                      )}

                      {result?.actions?.includes(`reassign_vendor`) && (
                        <Button
                          className="customized-btn"
                          color="success"
                          onClick={() => {
                            setCurrentSelectedBooking([{ ...result }]);
                            setCurrentActiveBookingAction(`reassign_vendor`);
                          }}
                        >
                          Reassign Vendor
                        </Button>
                      )}

                      {result?.actions?.includes(`assign_chauffeur`) && (
                        <Button
                          className="customized-btn"
                          color="success"
                          onClick={() => {
                            setCurrentSelectedBooking([{ ...result }]);
                            setCurrentActiveBookingAction(`assign_chauffeur`);
                          }}
                        >
                          Assign Chauffeur
                        </Button>
                      )}

                      {result?.actions?.includes(`change_chauffeur`) && (
                        <Button
                          className="customized-btn"
                          color="success"
                          onClick={() => {
                            setCurrentSelectedBooking([{ ...result }]);
                            setCurrentActiveBookingAction(`change_chauffeur`);
                          }}
                        >
                          Change Chauffeur
                        </Button>
                      )}

                      {result?.actions?.includes(`complete_booking`) && (
                        <Button
                          className="customized-btn"
                          color="success"
                          onClick={() => {
                            setCurrentSelectedBooking([{ ...result }]);
                            setCurrentActiveBookingAction(`complete_booking`);
                          }}
                        >
                          Mark As Complete
                        </Button>
                      )}

                      {result?.actions?.includes(`raise_a_dispute`) && (
                        <Button
                          className="customized-btn"
                          color="danger"
                          onClick={() => {
                            setCurrentSelectedBooking([{ ...result }]);
                            setCurrentActiveBookingAction(`raise_a_dispute`);
                          }}
                        >
                          Raise Dispute
                        </Button>
                      )}

                      {result?.actions?.includes(`vendor_accept`) && (
                        <Button
                          className="customized-btn"
                          color="success"
                          onClick={() => {
                            setCurrentSelectedBooking([{ ...result }]);
                            setCurrentActiveBookingAction(`vendor_accept`);
                          }}
                        >
                          Confirm
                        </Button>
                      )}

                      {result?.actions?.includes(`vendor_declined`) && (
                        <Button
                          className="customized-btn"
                          color="danger"
                          onClick={() => {
                            setCurrentSelectedBooking([{ ...result }]);
                            setCurrentActiveBookingAction(`vendor_declined`);
                          }}
                        >
                          Decline
                        </Button>
                      )}

                      {result?.actions?.includes(`approve`) && (
                        <Button
                          className="customized-btn"
                          color="primary"
                          onClick={() => {
                            setCurrentSelectedBooking([{ ...result }]);
                            setCurrentActiveBookingAction(`approval`);
                          }}
                        >
                          Approve
                        </Button>
                      )}

                      {result?.actions?.includes(`reject`) && (
                        <Button
                          className="customized-btn reject"
                          color="primary"
                          onClick={() => {
                            setCurrentSelectedBooking([{ ...result }]);
                            setCurrentActiveBookingAction(`reject`);
                          }}
                        >
                          Reject
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </>
        ))}
      </div>
    </div>
  );
};

export default CardView;
