import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, umsUrl, adminAPIURL } from '../../../../Containers/Config/index.js';
import { GET_DOWNLOAD_DUMP_ADMIN_V2, GET_DOWNLOAD_DUMP_ADMIN_V2_SUCCESS, GET_DOWNLOAD_DUMP_ADMIN_V2_ERROR } from '../actions.js';
import apiCall from '../../apiCall.js';

function* getDownloadDumpAdminV2({ payload }) {
  try {
    const filteredHeaders = { ...customHeaders };
    delete filteredHeaders['X-Requested-By'];

    const response = yield call(apiCall, {
      url: `${adminAPIURL}/v2/downloads/booking-dump-request-list`,
      method: 'GET',
      params: payload?.urlParams,
      headers: {
        // Authorization: Constant?.CLIENT_TOKEN,
        ...filteredHeaders,
        // withCredentials: true
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_DOWNLOAD_DUMP_ADMIN_V2_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_DOWNLOAD_DUMP_ADMIN_V2_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_DOWNLOAD_DUMP_ADMIN_V2_ERROR, payload: error });
  }
}

export function* watchDownloadDumpDumpAdminV2() {
  yield takeEvery(GET_DOWNLOAD_DUMP_ADMIN_V2, getDownloadDumpAdminV2);
}

function* getDownlaodDumpListSaga() {
  yield all([fork(watchDownloadDumpDumpAdminV2)]);
}

export default getDownlaodDumpListSaga;
