import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { UserFrameIcon } from '../../../../../../Components/icons';
import moment from 'moment';
import CountUp from 'react-countup';
import vendorAssign from '../../../../../../assets/custom/v2/vendor.svg';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { AiOutlinePercentage } from 'react-icons/ai';

const BookingTimelineCard = ({ item, handleBookingType, isSelected, cardData }) => {
  const eventHandle = () => {
    handleBookingType(item);
  };

  const totalCount =
    cardData?.[item?.name]?.data?.totalCount || cardData?.[item?.name]?.data?.totalCount === 0
      ? Number(cardData?.[item?.name]?.data?.totalCount)
      : null;
  return (
    <Card
      style={{
        borderRadius: '20px',
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 2px 0px',
        border: isSelected ? '1px solid #ABB9E8' : '1px solid transparent', // Selected border
        cursor: item?.toggleSection && 'pointer',
      }}
      className="sla-card"
    >
      <CardBody style={{ padding: '0.3rem 0.7rem 1rem 1rem' }}>
        <div className="d-flex justify-content-between mt-3" onClick={eventHandle}>
          <img src={item?.icon ? item?.icon : vendorAssign} className="img-fluid" alt="" style={{ maxWidth: '45px', height: '45px' }} />
          <div className="text-end">
            <p className="text-black text-format-7 m-0">{item?.displayName}</p>
            {totalCount != null ? (
              <CountUp separator="" start={0} end={totalCount} duration={2} className="fw-bold text-format-3 m-0" />
            ) : (
              <p className="fw-bold text-format-3 m-0">-</p> // Showing dash when data is missing
            )}

            {cardData?.[item?.name]?.data?.changePercentage !== null && cardData?.[item?.name]?.data?.changePercentage !== undefined && (
              <div className="d-flex justify-content-end align-items-center gap-3 mt-3">
                <div className="d-flex gap-2">
                  {cardData?.[item?.name]?.data?.changePercentage > 0 ? (
                    <>
                      {' '}
                      <span>
                        <FaArrowUp color={cardData?.[item?.name]?.data?.changePercentageColor || '#0AB39C'} />
                      </span>
                      <span style={{ marginTop: '1px' }}>
                        {' '}
                        <span style={{ color: cardData?.[item?.name]?.data?.changePercentageColor || '#0AB39C' }}>
                          {Math.abs(Number(cardData?.[item?.name]?.data?.changePercentage)).toFixed(2)}
                        </span>
                        <span>
                          <AiOutlinePercentage color={cardData?.[item?.name]?.data?.changePercentageColor || '#0AB39C'} />
                        </span>
                      </span>
                    </>
                  ) : cardData?.[item?.name]?.data?.changePercentage < 0 ? (
                    <>
                      <span>
                        <FaArrowDown color={cardData?.[item?.name]?.data?.changePercentageColor || 'red'} />
                      </span>
                      <span style={{ marginTop: '1px' }}>
                        {' '}
                        <span style={{ color: cardData?.[item?.name]?.data?.changePercentageColor || 'red' }}>
                          {Math.abs(Number(cardData?.[item?.name]?.data?.changePercentage)).toFixed(2)}
                        </span>
                        <span>
                          <AiOutlinePercentage color={cardData?.[item?.name]?.data?.changePercentageColor || 'red'} />
                        </span>
                      </span>
                    </>
                  ) : (
                    <p className="fs-12 m-0" style={{ color: '#0AB39C' }}>
                      No Change in Booking
                    </p>
                  )}
                </div>
                {cardData?.[item?.name]?.data?.changePercentage !== 0 && (
                  <p className="fs-12 m-0" style={{ color: '#8898AA' }}>
                    Since Yesterday
                  </p>
                )}
              </div>
            )}

            {item?.data?.endDate && item?.data?.startDate ? (
              <p className="mb-0 text-color-light-grey text-end">
                {moment(item?.data?.startDate).format("DD MMM'YY")} - {moment(item?.data?.endDate).format("DD MMM'YY")}
              </p>
            ) : (
              item?.data?.timelineFilter === 'upcoming' && (
                <p className="mb-0 text-color-light-grey text-end"> From {moment(item?.data?.startDate).format("DD MMM'YY")} </p>
              )
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default BookingTimelineCard;
