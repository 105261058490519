import { combineReducers } from 'redux';

// Front
import Layout from './layouts/reducer';

// Authentication
import Login from './auth/login/reducer';
import Account from './auth/register/reducer';
import ForgetPassword from './auth/forgetpwd/reducer';
import Profile from './auth/profile/reducer';

//Calendar
import Calendar from './calendar/reducer';
//Chat
import chat from './chat/reducer';
//Ecommerce
import Ecommerce from './ecommerce/reducer';

//Project
import Projects from './projects/reducer';

// Tasks
import Tasks from './tasks/reducer';
//Form advanced
import changeNumber from './formAdvanced/reducer';

//Crypto
import Crypto from './crypto/reducer';

//TicketsList
import Tickets from './tickets/reducer';
//Crm
import Crm from './crm/reducer';

//Invoice
import Invoice from './invoice/reducer';

//Mailbox
import Mailbox from './mailbox/reducer';

/* ---------------------------- THRIWE-One-Admin ---------------------------- */

// COMMON
import Countries from './application/common/country/reducer';
import Regions from './application/common/region/reducer';
import SubRegions from './application/common/subRegion/reducer';
import Airports from './application/common/airports/reducer';
import BookingTypes from './application/bookings/bookingTypes/reducer';
import DeleteFile from './application/coupon/deleteFile/reducer';

// USER LOGIN
import UserLogin from './application/common/login/reducer';

// USER LOGOUT
import UserLogout from './application/common/logout/reducer';

// Mail
import SendMail from './application/common/mail/reducer';

// All Projects
import AllProjects from './application/common/projects/reducer';

// All Golf Courses
import AllGolfCourses from './application/common/golfcourses/reducer';

/* ---------------------------- COMMON API'S ---------------------------- */
import GetRedemptionType from './application/supplyStore/common/getRedemptionType/reducer';
import GetParentCategory from './application/supplyStore/common/getParentCategory/reducer';
import MainAreaLoader from './application/common/mainAreaLoader/reducer';
import FullScreenLoader from './application/common/fullScreenLoader/reducer';
import PermissionsByProjectId from './application/common/getPermissionByProjectId/reducer';

/* ---------------------------- DASHBOARD ---------------------------- */
import GetSurgeReport from './application/Dashboard/SurgeReport/reducer';
import GetUsersList from './application/Dashboard/UserInfo/reducer';
import GetUserPoints from './application/Dashboard/UserPoints/reducer';
import GetUserBookings from './application/Dashboard/UserBookings/reducer';
import GetMisReport from './application/Dashboard/MisReport/reducer';

/* ---------------------------- SUPPLY STORE ---------------------------- */
import GetUploadsInfo from './application/supplyStore/common/getUploadsInfo/reducer';

// Category
import CreateCategory from './application/supplyStore/category/createCategory/reducer';
import GetCategory from './application/supplyStore/category/listCategory/reducer';
import UpdateCategory from './application/supplyStore/category/updateCategory/reducer';

// Location
import CreateLocation from './application/supplyStore/Location/createLocation/reducer';
import GetLocation from './application/supplyStore/Location/listLocation/reducer';
import UpdateLocation from './application/supplyStore/Location/updateLocation/reducer';

// Partner
import CreatePartner from './application/supplyStore/Partner/createPartner/reducer';
import GetPartners from './application/supplyStore/Partner/listPartner/reducer';
import UpdatePartner from './application/supplyStore/Partner/updatePartner/reducer';

// Service
import CreateService from './application/supplyStore/Service/createService/reducer';
import GetService from './application/supplyStore/Service/listService/reducer';
import UpdateService from './application/supplyStore/Service/updateService/reducer';

/* ---------------------------- COUPON MANAGEMENT ---------------------------- */
import GetCoupon from './application/coupon/getCoupon/reducer';
import GetCoupons from './application/coupon/getCoupons/reducer';
import GetCouponCategory from './application/coupon/category/reducer';
import GetCouponFacility from './application/coupon/facility/reducer';
import GetCouponPartner from './application/coupon/partner/reducer';
import GetCouponProject from './application/coupon/projects/reducer';
import GetCouponServices from './application/coupon/service/reducer';
import GetCouponAssignedStatusList from './application/coupon/assignedStatus/reducer';
import GetCouponUploadedByList from './application/coupon/uploadedBy/reducer';
import PostCoupons from './application/coupon/uploadCoupons/reducer';
import AssignCoupon from './application/coupon/AssignCoupon/reducer';
import UpdateCoupon from './application/coupon/UpdateCoupon/reducer';
import UploadStaticCoupon from './application/coupon/uploadStaticCoupon/reducer';
import GetStaticCoupons from './application/coupon/getStaticCoupon/reducer';
import UpdateStaticCoupon from './application/coupon/UpdateStaticCoupon/reducer';

/* ---------------------------- BOOKING MANAGEMENT ---------------------------- */
import Bookings from './application/bookings/bookingList/reducer';
import UpdateBooking from './application/bookings/updateBooking/reducer';
import ResendMail from './application/bookings/resendMail/reducer';
import VendorsList from './application/bookings/getVendorsList/reducer';
import GetVendorByBooking from './application/bookings/getVendor/reducer';
import ViewFile from './application/bookings/viewFile/reducer';

/* ---------------------------- CUSTOMER MANAGEMENT ---------------------------- */
import AllRights from './application/common/userRights/reducer';
import CreateUser from './application/common/createUser/reducer';
import ProgramUsers from './application/CustomerManagement/programsUser/reducer';
import MembershipDetails from './application/CustomerManagement/membershipDetails/reducer';
import CardDetails from './application/CustomerManagement/CardDetails/reducer';
import UpdateCustomer from './application/CustomerManagement/UpdateCustomer/reducer';

/* ---------------------------- DUPLICATE CARD MANAGEMENT ---------------------------- */
import DuplicateCards from './application/DuplicateCardManagement/DuplicateCardList/reducer';

/* ---------------------------- BENEFIT MANAGEMENT ---------------------------- */
import BenefitGroups from './application/common/benefitGroups/reducer';
import BenefitPack from './application/Benefits/BenefitPackList/reducer';
import Clients from './application/common/Clients/reducer';
import CreateBenefitPack from './application/Benefits/CreateBenefitPack/reducer';
import BenefitPackInfo from './application/Benefits/BenefitPackInfo/reducer';
import UpdateBenefitPack from './application/Benefits/UpdateBenefitPack/reducer';
import CreateInventoryQuota from './application/Benefits/CreateInventoryQuota/reducer';
import GetInventoryQuota from './application/Benefits/GetInventoryQuota/reducer';
import GetWorkflowsList from './application/Benefits/GetWorkflowsList/reducer';

/* ---------------------------- DOWNLOADS ---------------------------- */
import DownloadsList from './application/Downloads/DownloadList/reducer';
import DownloadDump from './application/Downloads/DownloadDump/reducer';

/* ---------------------------- BLOCK DATE MANAGEMENT ---------------------------- */
import CreateBlockDate from './application/BlockDate/CreateBlockDate/reducer';
import BlockedDatesList from './application/BlockDate/ListBlockDate/reducer';
import GetServiceOnFacility from './application/BlockDate/ServicesBasedOnFacility/reducer';
import DeactivateBlockDate from './application/BlockDate/DeactivateBlockDate/reducer';

/* ---------------------------- RULES MANAGEMENT ---------------------------- */
import CreateExpenditureRule from './application/RulesManagement/Expenditure/CreateExpenditureRule/reducer';
import ExpenditureRulesList from './application/RulesManagement/Expenditure/ListExpenditure/reducer';
import UpdateExpenditureRule from './application/RulesManagement/Expenditure/UpdateExpenditureRule/reducer';
import DeleteExpenditureRule from './application/RulesManagement/Expenditure/DeleteExpenditureRule/reducer';

import CreateEventRules from './application/RulesManagement/Event/CreateEventRule/reducer';
import CreateEventRulesWithUserIds from './application/RulesManagement/Event/CreateEventRuleWithUserIds/reducer';
import CreateEventRulesBenefitGroupId from './application/RulesManagement/Event/CreateEventRuleWithBenefitGroupId/reducer';
import EventRulesList from './application/RulesManagement/Event/ListEvent/reducer';
import UpdateEventRule from './application/RulesManagement/Event/UpdateEventRule/reducer';
import DeleteEventRule from './application/RulesManagement/Event/DeleteEventRule/reducer';

import CreateServiceWorth from './application/RulesManagement/ServiceWorth/CreateServiceWorth/reducer';
import ServiceWorthList from './application/RulesManagement/ServiceWorth/ListServiceWorth/reducer';
import UpdateServiceWorth from './application/RulesManagement/ServiceWorth/UpdateServiceWorth/reducer';
import DeleteServiceWorth from './application/RulesManagement/ServiceWorth/DeleteServiceWorth/reducer';

/* ---------------------------- GOLF ADMIN ---------------------------- */
import GetGolfCourseFormatById from './application/GolfAdmin/getGolfCourseFormatById/reducer';
import GetGolfCourseFormats from './application/GolfAdmin/getGolfCourseFormats/reducer';
import deleteGolfCourseFormatById from './application/GolfAdmin/deleteGolfCourseFormatById/reducer';
import GetGolfCourseUnavailable from './application/GolfAdmin/getGolfCourseUnavailable/reducer';
import createGolfCourseSlot from './application/GolfAdmin/createGolfCourseSlot/reducer';
import GetGolfCourses from './application/GolfAdmin/golfCourses/reducer';
import GolfCourseSlots from './application/GolfAdmin/getGolfCourseSlots/reducer';
import DownloadExcelReport from './application/GolfAdmin/getGolfCourseFormatById/reducer';
import DisableGolfCourseUnavailability from './application/GolfAdmin/disableGolfCourseUnavailablility/reducer';
import createGolfCourseUnavailability from './application/GolfAdmin/createGolfCourseUnavailablility/reducer';
import UpdateGolfCourseSlots from './application/GolfAdmin/updateGolfCourseSlots/reducer';

/* ---------------------------- USER MANAGEMENT ---------------------------- */
import Users from './application/UsersMangement/GetUsers/reducer';
import Permissions from './application/UsersMangement/GetPermissions/reducer';
import UpdatePermissions from './application/UsersMangement/UpdatePermissions/reducer';
import BulkUsers from './application/CustomerManagement/GetBulkUsers/reducer';
import UserCards from './application/CustomerManagement/GetUserCards/reducer';
import UpdateUserCard from './application/CustomerManagement/UpdateUserCard/reducer';
import Memberships from './application/CustomerManagement/GetMemberships/reducer';
import GetUserDetails from './application/CustomerManagement/GetUserDetails/reducer';

/* ---------------------------- PROJECT MANAGEMENT ---------------------------- */
import CreateProject from './application/ProjectManagement/CreateProject/reducer';
import ProjectsList from './application/ProjectManagement/ProjectsList/reducer';
import GetPaymentGateways from './application/ProjectManagement/GetPaymentGateways/reducer';
import GetPaymentsList from './application/ProjectManagement/GetPaymentsList/reducer';
import CreatePayment from './application/ProjectManagement/CreatePayment/reducer';
import UpdatePayment from './application/ProjectManagement/UpdatePayment/reducer';
import BinsList from './application/ProjectManagement/ListBins/reducer';
import UpdateBins from './application/ProjectManagement/UpdateBins/reducer';
import CreateBin from './application/ProjectManagement/CreateBins/reducer';
import PaymentLogs from './application/ProjectManagement/PaymentLogs/reducer';
import CreateClient from './application/ProjectManagement/CreateClient/reducer';
import CreateParentProject from './application/ProjectManagement/CreateParentProject/reducer';
import ParentProjects from './application/ProjectManagement/GetParentProjects/reducer';
import UpdateParentProject from './application/ProjectManagement/UpdateParentProject/reducer';
import UpdateProject from './application/ProjectManagement/UpdateProject/reducer';
import UpdateClient from './application/ProjectManagement/UpdateClient/reducer';

/* ---------------------------- CONFIG STORE MANAGEMENT ---------------------------- */
import CreateBenefitGroup from './application/ConfigStoreManagement/BenefitGroup/reducer';
import CreateBenefitGroupItems from './application/ConfigStoreManagement/BenefitGroupItem/reducer';
import BenefitGroupCategories from './application/ConfigStoreManagement/BenefitGroupCategories/reducer';
import CreateBenefitGroupCategory from './application/ConfigStoreManagement/CreateBenefitGroupCategory/reducer';
import GetBenefitGroups from './application/ConfigStoreManagement/BenefitGroupsList/reducer';
import GetServicesInBenefitItem from './application/ConfigStoreManagement/Service/reducer';
import UpdateBenefitGroup from './application/ConfigStoreManagement/UpdateBenefitGroup/reducer';
import UpdateBenefitGroupItems from './application/ConfigStoreManagement/UpdateBenefitGroupItems/reducer';
import GetBenefitGroupItemsList from './application/ConfigStoreManagement/BenefitGroupItemsList/reducer';
import BenefitItemsList from './application/ConfigStoreManagement/BenefitItemsList/reducer';
import UpdateBenefitItems from './application/ConfigStoreManagement/UpdateItems/reducer';

/* ---------------------------- CUSTOMER DATA MANAGEMENT ---------------------------- */
import CustomerUploadsInfo from './application/CustomerDataManagement/CustomerUploadsInfo/reducer';
import ClientsCustomer from './application/CustomerDataManagement/ClientsCustomer/reducer';
import UpdateClientsCustomer from './application/CustomerDataManagement/UpdateClientsCustomer/reducer';

/* ----------------------------------- SURGE SPHERE ----------------------------------- */
// CAMPAIGN
import CreateCampaign from './application/SurgeSphere/Campaign/createCampaign/reducer';
import GetCampaigns from './application/SurgeSphere/Campaign/listCampaign/reducer';
import UpdateCampaign from './application/SurgeSphere/Campaign/updateCampaign/reducer';

// RULES
import CreateRule from './application/SurgeSphere/Rules/createRule/reducer';
import GetRules from './application/SurgeSphere/Rules/listRule/reducer';
import UpdateRule from './application/SurgeSphere/Rules/updateRule/reducer';
import DeleteRule from './application/SurgeSphere/Rules/deleteRule/reducer';

// EVENTS
import CreateEvent from './application/SurgeSphere/Event/createEvent/reducer';
import GetEvents from './application/SurgeSphere/Event/listEvent/reducer';
import UpdateEvent from './application/SurgeSphere/Event/updateEvent/reducer';
import DeleteEvent from './application/SurgeSphere/Event/DeleteEvent/reducer';

// ATTRIBUTES
import CreateAttribute from './application/SurgeSphere/Attributes/createAttribute/reducer';
import GetAttributes from './application/SurgeSphere/Attributes/listAttribute/reducer';
import UpdateAttribute from './application/SurgeSphere/Attributes/updateAttribute/reducer';
import DeleteAttribute from './application/SurgeSphere/Attributes/deleteAttribute/reducer';

// WALLETS
import GetWallets from './application/SurgeSphere/Wallet/walletsList/reducer';

/* ----------------------------------- DATA POOL ----------------------------------- */
import FilesSummary from './application/DataPool/GetFilesSummary/reducer';
import FileSummary from './application/DataPool/GetFileRowSummary/reducer';
import UploadFile from './application/DataPool/UploadData/reducer';

/* ----------------------------------- DATA INTEGRATION ----------------------------------- */
import IntegrationData from './application/ProjectManagement/GetIntegrationData/reducer';
import UpdateIntegrationData from './application/ProjectManagement/UpdateIntegrationData/reducer';
import CreateIntegrationData from './application/ProjectManagement/CreateDataIntegration/reducer';

/* ----------------------------------- COMMUNICATION ----------------------------------- */
import CreateTemplate from './application/Communication/CreateTemplate/reducer';
import GetTemplates from './application/Communication/TemplateList/reducer';
import UpdateTemplate from './application/Communication/UpdateTemplate/reducer';
import CreateComsConfig from './application/Communication/CreateComsConfig/reducer';
import UpdateComsConfig from './application/Communication/UpdateComsConfig/reducer';
import GetComsConfig from './application/Communication/ComsConfigList/reducer';
import GetComsEvents from './application/Communication/ComsEventList/reducer';
import GetTemplateDetails from './application/Communication/TemplateList/reducer';
import CreateComsChannelConfig from './application/Communication/CreateComsChannel/reducer';
import UpdateComsChannelConfig from './application/Communication/UpdateComsChannel/reducer';
import GetComsChannelConfig from './application/Communication/GetComsChannel/reducer';
import GetComsBenefit from './application/Communication/GetComsBenefit/reducer';
import GetVariables from './application/Communication/Variables/reducer';
import SendCommunication from './application/Communication/SendCommunication/reducer';

/* ----------------------------------- DASHBOARD ----------------------------------- */
import GetBookingSummary from './application/Dashboard/BookingSummary/reducer';

/* ----------------------------------- THRIWE PRIME BOOKING ----------------------------------- */
import ThriwePrimeBookingList from './application/bookings/thriwePrimeBooking/reducer';

/* ----------------------------------- REPORTS MANAGEMENT ----------------------------------- */
import GetCommsReport from './application/Reports/CommsReport/reducer';

/* ----------------------------------- VENDOR PANEL MANAGEMENT ----------------------------------- */
import GetVendors from './application/VendorPanel/VendorsList/reducer';
import CreateVendor from './application/VendorPanel/CreateVendor/reducer';
import GetBenefitDomains from './application/VendorPanel/BenefitDomainsList/reducer';
import CreateVendorAgent from './application/VendorPanel/CreateVendorAgent/reducer';

/* ----------------------------------- UPDATE MEMBERSHIP ----------------------------------- */
import UpdateMembership from './application/CustomerManagement/UpdateBenefitPack/reducer';

/* ----------------------------------- CLIENT PANEL MANAGEMENT ----------------------------------- */
import ClientLogin from './application/Client/ClientLogin/reducer';
import ValidateToken from './application/Client/ValidateToken/reducer';
import ClientForgotPassword from './application/Client/ForgotPassword/reducer';
import ClientResetPassword from './application/Client/ResetPassword/reducer';
import CreateBooking from './application/Client/CreateBooking/reducer';
import UpdateProfile from './application/Client/UpdateProfile/reducer';
import GetMembershipsData from './application/Client/GetMembershipsData/reducer';
import GetBenefitItems from './application/Client/GetBenefitItem/reducer';
import GetBinsList from './application/Client/GetBins/reducer';
import CreateClientUser from './application/Client/CreateUser/reducer';
import GetClientHistoryList from './application/Client/History/reducer';
import GetClientBenefitConfig from './application/Client/GetBenefitConfig/reducer';
import GetClientAirport from './application/Client/GetClientAirport/reducer';
import ClientDownloadsList from './application/Client/DownloadList/reducer';
import ClientDownloadDump from './application/Client/DownloadDump/reducer';
import SendEmail from './application/Client/SendEMail/reducer';
import ClientRegions from './application/Client/subRegion/reducer';
import GetProjectUtility from './application/Client/ProjectUtility/reducer';

import GetBusiness from './application/Client/GetBusiness/reducer';
import UpdateBusiness from './application/Client/UpdateBusiness/reducer';
import GetQuestionAnswer from './application/Client/GetQuestionAnswer/reducer';
import GetPost from './application/Client/GetPost/reducer';
import UpdatePost from './application/Client/UpdatePost/reducer';
import GetOffer from './application/Client/GetOffer/reducer';
import UpdateOffer from './application/Client/UpdateOffer/reducer';
import AskQuestion from './application/Client/AskQuestion/reducer';
import GetAnnouncements from './application/Client/GetAnnouncements/reducer';
import CreateAnnoucement from './application/Client/PostAnnouncement/reducer';
import GetPostTypes from './application/Client/GetPostTypes/reducer';
import PostImage from './application/Client/UploadImage/reducer';
import AdibSurgeReport from './application/Client/SurgeReport/reducer';
import GetAdibMonthlySummary from './application/Client/GetMonthlySummary/reducer';
import GetAdibPartnersMonthlySummary from './application/Client/GetPartnerMonthlySummary/reducer';
import GetClientCustomers from './application/Client/Customers/reducer';
import DownloadBookingsDump from './application/Client/DownloadBookings/reducer';
import DownloadBookingsInfo from './application/Client/DownloadsInfo/reducer';
import DownloadAdibBookingDump from './application/Client/DownloadAdibBookings/reducer';
import GetPostSetting from './application/Client/GetPostSetting/reducer';
import UpdatePostSetting from './application/Client/UpdatePostSetting/reducer';
import UpdateUserSetting from './application/Client/UpdateUserSetting/reducer';
import ClientDownloadDumpADIB from './application/Client/DownloadInfoAdib/reducer';
import GetClientUser from './application/Client/ClientUsers/reducer';
import GetClientRoleForUser from './application/Client/GetClientRoleForUser/reducer';
import UpdateClientRole from './application/Client/UpdateRole/reducer';
import GetClientRole from './application/Client/GetClientRole/reducer';
import CreateAdibClient from './application/Client/CreateAdibClient/reducer';
import GetClientPermissionForUser from './application/Client/GetPermissionForUser/reducer';
import UpdateUserStatus from './application/Client/UpdateUserStatus/reducer';
import GetAdibDashboard from './application/Client/AdibDashboard/reducer';
import DownloadUserLogs from './application/Client/DownloadUserLogs/reducer';
import DownloadUserReport from './application/Client/DownloadUserReport/reducer';
import GetUserDownloads from './application/Client/UserDownloads/reducer';

/* ----------------------------------- AIRPORT TRANSFER MANUAL BOOKING ----------------------------------- */
import GetAdminMembershipsData from './application/bookings/GetMembershipData/reducer';
import GetBenefitCategory from './application/bookings/GetBenefitCategory/reducer';
import GetUserCardData from './application/bookings/GetUserCards/reducer';
import GetMembershipQuota from './application/bookings/GetMembershipQuota/reducer';
import CreateManualBooking from './application/bookings/CreateManualBooking/reducer';
import GetAdminBenefitItems from './application/bookings/GetBenefitItem/reducer';
import CreateUploadTicket from './application/bookings/UploadTicket/reducer';

/* ----------------------------------- VENDOR ----------------------------------- */
import VendorLogin from './application/Vendor/Login/reducer';
import GetVendorsList from './application/VendorPanel/VendorsList/reducer';
import GetVendorsDashboardData from './application/Vendor/Dashboard/reducer';
import GetVendorBookings from './application/Vendor/VendorBookings/reducer';
import VendorDownloadDump from './application/Vendor/DownloadDump/reducer';
import VendorDownloadsList from './application/Vendor/DownloadList/reducer';
import VendorUpdateBooking from './application/Vendor/UpdateVendorBookings/reducer';
import VendorOtpValidate from './application/Vendor/ValidateOtp/reducer';
import VendorResendOtp from './application/Vendor/ResendOtp/reducer';
import VendorGenerateQr from './application/Vendor/GenerateQr/reducer';
import GetUserProfile from './application/Vendor/GetProfile/reducer';
import ValidateUser from './application/CustomerManagement/ValidateUser/reducer';

/* ----------------------------------- BOOKING TYPE V2 ----------------------------------- */
import BookingsV2 from './application/Bookings_V2/BookingList/reducer';
import ConfirmBookingV2 from './application/Bookings_V2/ConfirmBooking/reducer';
import CancelBookingV2 from './application/Bookings_V2/CancelBooking/reducer';
import ProccessBookingV2 from './application/Bookings_V2/ProccessBooking/reducer';
import CompleteBookingV2 from './application/Bookings_V2/CompleteBooking/reducer';
import CancelBookingGolf from './application/Bookings_V2/Golf/CancelBooking/reducer';
import ConfirmBookingGolf from './application/Bookings_V2/Golf/ConfirmBooking/reducer';
import DownloadDumpV2 from './application/Bookings_V2/DownloadBooking/reducer';
import DwonloadListV2 from './application/Bookings_V2/DownloadList/reducer';

/* ----------------------------------- PERMISSION CHECKER ----------------------------------- */
import PermissionChecker from './application/common/permission-checker/reducer';
import UpdatePermissionRequest from './application/common/UpdateRequestStatus/reducer';
import BookingsSearch from './application/bookings/bookingSearch/reducer';

/* ----------------------------------- GM REPORT ----------------------------------- */
import GetFileTypes from './application/GmReport/GetFileTypes/reducer';
import GetFileTypesConfig from './application/GmReport/GetFileTypesConfig/reducer';
import GetRecordData from './application/GmReport/GetRecordData/reducer';
import GetTrackerData from './application/GmReport/GetTrackerData/reducer';
import UploadConfigExcel from './application/GmReport/UploadExcel/reducer';
import DownloadExcel from './application/GmReport/DownloadExcel/reducer';
import UpdateRecordData from './application/GmReport/UpdateRecord/reducer';
import PublishRecord from './application/GmReport/PublishRecord/reducer';
import ApproveRejectRecords from './application/GmReport/ApproveReject/reducer';
import SendApprovalMail from './application/GmReport/SendMail/reducer';
import DeleteTrackerRecord from './application/GmReport/DeleteTrackerRecord/reducer';
import GetClientPrice from './application/GmReport/GetClientPrice/reducer';
import GetAmenitiesDetails from './application/GmReport/Amenities/reducer';
import GetPriceDetails from './application/GmReport/GetPriceDetails/reducer';
import PublishDataWithAuth from './application/GmReport/PublishDataWithAuth/reducer';
import PricePreview from './application/GmReport/PricePreview/reducer';
import UploadAttachment from './application/GmReport/UploadAttachment/reducer';
import DeleteAttachment from './application/GmReport/DeleteAttachment/reducer';
import GetReconDetails from './application/GmReport/GetReconDetails/reducer';
import GetGmReport from './application/GmReport/GetGmReport/reducer';
import GetReconPreview from './application/GmReport/GetReconPreview/reducer';

/* ----------------------------------- SURGE V2 ----------------------------------- */
// RULES
import CreateSurgeRule from './application/Surge-v2/Rules/CreateRule/reducer';
import CreateSurgeWorkflow from './application/Surge-v2/Rules/CreateSurgeWorkflow/reducer';
import GetSurgeRulesList from './application/Surge-v2/Rules/ListRules/reducer';
import ListSurgeRuleTables from './application/Surge-v2/Rules/ListRuleTables/reducer';

// REFERRAL
import GetReferral from './application/Surge-v2/Referral/GetReferral/reducer';
import ValidateReferral from './application/Surge-v2/Referral/ValidateReferral/reducer';
import GetLedger from './application/Surge-v2/Ledger/GetLedger/reducer';

// EVENTS
import ListSurgeEvents from './application/Surge-v2/Events/ListEvents/reducer';
import GetUserRegistration from './application/Surge-v2/UserRegistration/GetUserRegistration/reducer';
import UserRegistration from './application/Surge-v2/UserRegistration/UploadFile/reducer';

// EFFECTS
import ListSurgeEffects from './application/Surge-v2/Effects/ListEffects/reducer';
import GetSampleFile from './application/Surge-v2/UserRegistration/GetSampleFile/reducer';

// VOUCHER
import GetVoucherBooking from './application/Surge-v2/Voucher/VoucherBooking/reducer';
import GetVoucherBookingDetails from './application/Surge-v2/Voucher/VoucherBookingDetails/reducer';
import ValidateVoucher from './application/Surge-v2/Voucher/ValidateVoucherBooking/reducer';
import ChaufferAssign from './application/Bookings_V2/ChaufferAssignV2/reducer';

// GLOBE TOYOTA V2
import GetAnnouncementForGlobe from './application/Surge-v2/Announcement/GetAnnouncement/reducer';
import CreateAnnouncementForGlobeToyota from './application/Surge-v2/Announcement/CreateAnnouncement/reducer';
import UpdateAnnouncementForGlobeToyota from './application/Surge-v2/Announcement/UpdateAnnoucement/reducer';
import GetTemplateList from './application/Surge-v2/Rules/ListTemplate/reducer';
import TemplateDetails from './application/Surge-v2/Rules/TemplateDetails/reducer';
import RuleDetails from './application/Surge-v2/Rules/RuleDetails/reducer';
import CreateTemplateWorkflow from './application/Surge-v2/Rules/CreateTemplateWorkflow/reducer';
import CreateTemplateMapping from './application/Surge-v2/Rules/CreateTemplateMapping/reducer';
import GetRuleDashboard from './application/Surge-v2/GetRuleDashboard/reducer';

import UserLoginV2 from './application/common/loginV2/reducer';
import UserToken from './application/V2/UserToken/reducer';
import VendorLoginV2 from './application/common/VendorLoginV2/reducer';

import AssignChauffeur from './application/V2/Bookings/AirportTransfer/AssignChauffeur/reducer';
import BookingAssignToMe from './application/V2/Bookings/AirportTransfer/AssignToMe/reducer';
import AssignVendor from './application/V2/Bookings/AirportTransfer/AssignVendor/reducer';
import CancelBookingByVendor from './application/V2/Bookings/AirportTransfer/CancelBookingByVendor/reducer';
import ConfirmBookingByVendor from './application/V2/Bookings/AirportTransfer/ConfirmBookingByVendor/reducer';
import CreateChauffeur from './application/V2/Bookings/AirportTransfer/CreateChauffeur/reducer';
import GetChauffersList from './application/V2/Bookings/AirportTransfer/GetChauffeursList/reducer';
import GetCarsListOfVendor from './application/V2/Bookings/AirportTransfer/GetCarsListOfVendor/reducer';
import GetVendorsListData from './application/V2/Bookings/AirportTransfer/GetVendorsList/reducer';
import GetBookingsList from './application/V2/Bookings/AirportTransfer/GetBookingsList/reducer';
import GetBookingDetails from './application/V2/Bookings/AirportTransfer/GetBookingDetails/reducer';
import GetFiltersList from './application/V2/Bookings/AirportTransfer/GetFiltersList/reducer';
import GetFilterValues from './application/V2/Bookings/AirportTransfer/GetFilterValues/reducer';
import CancellationRemarksForVendor from './application/V2/Bookings/AirportTransfer/CancellationRemarksForVendor/reducer';
import AssignVendorRemarksList from './application/V2/Bookings/AirportTransfer/AssignVendorRemarksList/reducer';
import BookingType from './application/V2/BookingType/reducer';
import ChangeActivity from './application/V2/ChangeActivity/reducer';
import V2Dashboard from './application/V2/Dashboard/reducer';
import UserRoles from './application/V2/UserRole/reducer';
import CancelBookingByAdmin from './application/V2/Bookings/AirportTransfer/CancelBookingByAdmin/reducer';
import FetchActivity from './application/V2/FetchActivity/reducer';
import GetDashboardConfiguration from './application/V2/GetDashboardConfiguration/reducer';
import GetCardSummary from './application/V2/GetCardSummary/reducer';
import GetBookingTypeForGlobalSearch from './application/V2/Bookings/GetBookingType/reducer';
import GetBookingGlobalSearch from './application/V2/Bookings/GetGlobalSearchBooking/reducer';
import ShowModifyField from './application/V2/Bookings/AirportTransfer/ModifyFields/reducer';
import ModifyBooking from './application/V2/Bookings/AirportTransfer/ModifyBooking/reducer';
import ApprovalActionBooking from './application/V2/Bookings/AirportTransfer/ApprovalAction/reducer';
import RemarkStatusDetails from './application/V2/DynamicReason/GetRemarkStatus/reducer';
import GetActionByStatus from './application/V2/DynamicReason/GetActionByStatus/reducer';
import GetReasonByActions from './application/V2/DynamicReason/GetReason/reducer';
import AddReason from './application/V2/DynamicReason/AddReason/reducer';
import UpdateReason from './application/V2/DynamicReason/UpdateReason/reducer';
import CompleteBooking from './application/V2/Bookings/AirportTransfer/CompleteBooking/reducer';
import RaiseDispute from './application/V2/Bookings/AirportTransfer/RaiseDispute/reducer';
import GetBookingDumpTypeList from './application/V2/GetBookingDumpTypeList/reducer';
import PostDownloadDumpAdminV2 from './application/V2/DownloadDump/reducer';
import GetDownloadDumpList from './application/V2/GetDownloadDumpList/reducer';
import BulkBookingConfirmationByVendor from './application/V2/Bookings/AirportTransfer/BulkBookingConfirm/reducer';

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Calendar,
  chat,
  Projects,
  Ecommerce,
  Tasks,
  changeNumber,
  Crypto,
  Tickets,
  Crm,
  Invoice,
  Mailbox,
  DeleteFile,

  /* ---------------------------- COMMON API'S ---------------------------- */
  Countries,
  Regions,
  SubRegions,
  Airports,
  BookingTypes,

  MainAreaLoader,
  FullScreenLoader,

  AllProjects,
  AllGolfCourses,
  UserLogin,
  UserLogout,
  SendMail,
  GetParentCategory,
  GetRedemptionType,
  PermissionsByProjectId,

  /* ---------------------------- DASHBOARD ---------------------------- */
  GetSurgeReport,
  GetUsersList,
  GetUserPoints,
  GetUserBookings,
  GetMisReport,

  /* ---------------------------- SUPPLY STORE ---------------------------- */
  GetUploadsInfo,

  // Category
  CreateCategory,
  UpdateCategory,
  GetCategory,

  // Location
  CreateLocation,
  UpdateLocation,
  GetLocation,

  // Partner
  CreatePartner,
  UpdatePartner,
  GetPartners,

  // Service
  CreateService,
  UpdateService,
  GetService,

  /* ---------------------------- COUPON MANAGEMENT ---------------------------- */
  GetCoupon,
  GetCoupons,
  GetCouponCategory,
  GetCouponFacility,
  GetCouponPartner,
  GetCouponProject,
  GetCouponServices,
  GetCouponAssignedStatusList,
  GetCouponUploadedByList,
  PostCoupons,
  AssignCoupon,
  UpdateCoupon,
  UploadStaticCoupon,
  GetStaticCoupons,
  UpdateStaticCoupon,

  /* ---------------------------- BOOKING MANAGEMENT ---------------------------- */
  Bookings,
  UpdateBooking,
  ResendMail,
  VendorsList,
  GetVendorByBooking,
  ViewFile,

  /* ---------------------------- USER MANAGEMENT ---------------------------- */
  AllRights,
  CreateUser,
  ProgramUsers,
  MembershipDetails,
  CardDetails,
  BulkUsers,
  UserCards,
  UpdateUserCard,
  Memberships,
  UpdateCustomer,
  GetUserDetails,
  ValidateUser,

  /* ---------------------------- DUPLICATE CARD MANAGEMENT ---------------------------- */
  DuplicateCards,

  /* ---------------------------- BENEFIT MANAGEMENT ---------------------------- */
  BenefitGroups,
  BenefitPack,
  Clients,
  CreateBenefitPack,
  BenefitPackInfo,
  UpdateBenefitPack,
  CreateInventoryQuota,
  GetInventoryQuota,
  GetWorkflowsList,

  /* ---------------------------- DOWNLOADS ---------------------------- */
  DownloadsList,
  DownloadDump,

  /* ---------------------------- BLOCK DATE ---------------------------- */
  CreateBlockDate,
  BlockedDatesList,
  GetServiceOnFacility,
  DeactivateBlockDate,

  /* ---------------------------- RULE MANAGEMENT ---------------------------- */
  CreateExpenditureRule,
  ExpenditureRulesList,
  UpdateExpenditureRule,
  DeleteExpenditureRule,

  CreateEventRules,
  CreateEventRulesWithUserIds,
  CreateEventRulesBenefitGroupId,
  EventRulesList,
  UpdateEventRule,
  DeleteEventRule,

  CreateServiceWorth,
  ServiceWorthList,
  UpdateServiceWorth,
  DeleteServiceWorth,

  /* ---------------------------- GOLF ADMIN ---------------------------- */
  GetGolfCourseFormatById,
  GetGolfCourseFormats,
  deleteGolfCourseFormatById,
  GetGolfCourseUnavailable,
  createGolfCourseSlot,
  GetGolfCourses,
  GolfCourseSlots,
  DownloadExcelReport,
  DisableGolfCourseUnavailability,
  createGolfCourseUnavailability,
  UpdateGolfCourseSlots,

  /* ---------------------------- USER MANAGEMENT ---------------------------- */
  Users,
  Permissions,
  UpdatePermissions,

  /* ---------------------------- PROJECT MANAGEMENT ---------------------------- */
  CreateProject,
  ProjectsList,
  GetPaymentGateways,
  GetPaymentsList,
  CreatePayment,
  UpdatePayment,
  BinsList,
  UpdateBins,
  CreateBin,
  PaymentLogs,
  CreateClient,
  CreateParentProject,
  ParentProjects,
  UpdateParentProject,
  UpdateProject,
  UpdateClient,

  /* ---------------------------- CONFIG STORE MANAGEMENT ---------------------------- */
  CreateBenefitGroup,
  CreateBenefitGroupItems,
  CreateBenefitGroupCategory,
  BenefitGroupCategories,
  GetBenefitGroups,
  GetServicesInBenefitItem,
  UpdateBenefitGroup,
  UpdateBenefitGroupItems,
  GetBenefitGroupItemsList,
  BenefitItemsList,
  UpdateBenefitItems,

  /* ---------------------------- CUSTOMER DATA MANAGEMENT ---------------------------- */
  CustomerUploadsInfo,
  ClientsCustomer,
  UpdateClientsCustomer,

  // CAMPAIGN
  CreateCampaign,
  GetCampaigns,
  UpdateCampaign,

  // RULES
  CreateRule,
  GetRules,
  UpdateRule,
  DeleteRule,

  // EVENTS
  CreateEvent,
  GetEvents,
  UpdateEvent,
  DeleteEvent,

  // ATTRIBUTES
  CreateAttribute,
  GetAttributes,
  UpdateAttribute,
  DeleteAttribute,

  // WALLETS
  GetWallets,

  /* ----------------------------------- DATA POOL ----------------------------------- */
  FilesSummary,
  FileSummary,
  UploadFile,

  /* ----------------------------------- DATA INTEGRATION ----------------------------------- */
  IntegrationData,
  UpdateIntegrationData,
  CreateIntegrationData,

  /* ---------------------------- COMMUNICATION ---------------------------- */
  CreateTemplate,
  GetTemplates,
  UpdateTemplate,
  CreateComsConfig,
  UpdateComsConfig,
  GetComsConfig,
  GetComsEvents,
  GetTemplateDetails,
  CreateComsChannelConfig,
  UpdateComsChannelConfig,
  GetComsChannelConfig,
  GetComsBenefit,
  GetVariables,
  SendCommunication,

  /* ----------------------------------- DASHBOARD ----------------------------------- */
  GetBookingSummary,

  /* ----------------------------------- THRIWE PRIME BOOKING ----------------------------------- */
  ThriwePrimeBookingList,

  /* ----------------------------------- REPORTS MANAGEMENT ----------------------------------- */
  GetCommsReport,

  /* ----------------------------------- VENDOR PANEL MANAGEMENT ----------------------------------- */
  GetVendors,
  CreateVendor,
  GetBenefitDomains,
  CreateVendorAgent,

  /* ----------------------------------- UPDATE MEMBERSHIP ----------------------------------- */
  UpdateMembership,

  /* ----------------------------------- CLIENT PANEL MANAGEMENT ----------------------------------- */
  ClientLogin,
  ValidateToken,
  ClientForgotPassword,
  ClientResetPassword,
  CreateBooking,
  UpdateProfile,
  GetMembershipsData,
  GetBenefitItems,
  GetBinsList,
  CreateClientUser,
  GetClientHistoryList,
  GetClientBenefitConfig,
  GetClientAirport,
  ClientDownloadsList,
  ClientDownloadDump,
  SendEmail,
  ClientRegions,
  GetBusiness,
  UpdateBusiness,
  GetQuestionAnswer,
  GetPost,
  UpdatePost,
  GetOffer,
  UpdateOffer,
  AskQuestion,
  GetAnnouncements,
  CreateAnnoucement,
  GetPostTypes,
  PostImage,
  AdibSurgeReport,
  GetAdibMonthlySummary,
  GetAdibPartnersMonthlySummary,
  GetClientCustomers,
  DownloadBookingsDump,
  DownloadBookingsInfo,
  DownloadAdibBookingDump,
  ClientDownloadDumpADIB,
  GetPostSetting,
  UpdatePostSetting,
  UpdateUserSetting,
  GetClientUser,
  GetClientRoleForUser,
  GetClientRole,
  UpdateClientRole,
  CreateAdibClient,
  GetClientPermissionForUser,
  UpdateUserStatus,
  GetAdibDashboard,
  DownloadUserLogs,
  DownloadUserReport,
  GetUserDownloads,

  /* ----------------------------------- VENDOR ----------------------------------- */
  VendorLogin,
  GetVendorsList,
  GetVendorsDashboardData,
  GetVendorBookings,
  VendorDownloadDump,
  VendorDownloadsList,
  VendorUpdateBooking,
  VendorOtpValidate,
  VendorResendOtp,
  VendorGenerateQr,
  GetUserProfile,
  /* ----------------------------------- AIRPORT TRANSFER MANUAL BOOKING ----------------------------------- */
  GetAdminMembershipsData,
  GetBenefitCategory,
  GetUserCardData,
  GetMembershipQuota,
  CreateManualBooking,
  GetAdminBenefitItems,
  CreateUploadTicket,

  /* ----------------------------------- GM REPORT ----------------------------------- */
  GetFileTypes,
  GetFileTypesConfig,
  GetRecordData,
  GetTrackerData,
  UploadConfigExcel,
  DownloadExcel,
  UpdateRecordData,
  PublishRecord,
  ApproveRejectRecords,
  SendApprovalMail,
  DeleteTrackerRecord,
  GetClientPrice,
  GetAmenitiesDetails,
  GetPriceDetails,
  PublishDataWithAuth,
  PricePreview,
  UploadAttachment,
  DeleteAttachment,
  GetReconDetails,
  GetGmReport,
  GetReconPreview,

  /* ----------------------------------- CLIENT PROJECT UTILTIES ----------------------------------- */
  GetProjectUtility,

  /* ----------------------------------- BOOKING TYPE V2 ----------------------------------- */
  BookingsV2,
  CancelBookingV2,
  ConfirmBookingV2,
  ProccessBookingV2,
  CompleteBookingV2,
  CancelBookingGolf,
  ConfirmBookingGolf,
  DownloadDumpV2,
  DwonloadListV2,

  /* ----------------------------------- PERMISSION CHECKER ----------------------------------- */
  PermissionChecker,
  UpdatePermissionRequest,
  BookingsSearch,

  /* ----------------------------------- SURGE V2 ----------------------------------- */
  // RULES
  CreateSurgeRule,
  CreateSurgeWorkflow,
  GetSurgeRulesList,
  ListSurgeRuleTables,

  //REFERRAL
  GetReferral,
  ValidateReferral,
  GetLedger,

  // EVENTS
  ListSurgeEvents,

  // EFFECTS
  ListSurgeEffects,
  GetUserRegistration,
  UserRegistration,

  // VOUCHER
  GetVoucherBooking,
  GetVoucherBookingDetails,
  ValidateVoucher,
  GetSampleFile,

  GetAnnouncementForGlobe,
  CreateAnnouncementForGlobeToyota,
  UpdateAnnouncementForGlobeToyota,
  GetTemplateList,
  TemplateDetails,
  RuleDetails,
  CreateTemplateWorkflow,
  CreateTemplateMapping,
  GetRuleDashboard,

  ChaufferAssign,

  UserLoginV2,
  UserToken,
  VendorLoginV2,
  AssignChauffeur,
  BookingAssignToMe,
  AssignVendor,
  CancelBookingByVendor,
  ConfirmBookingByVendor,
  CreateChauffeur,
  GetChauffersList,
  GetCarsListOfVendor,
  GetVendorsListData,
  GetBookingsList,
  GetBookingDetails,
  GetFiltersList,
  GetFilterValues,
  CancellationRemarksForVendor,
  AssignVendorRemarksList,
  BookingType,
  ChangeActivity,
  V2Dashboard,
  UserRoles,
  CancelBookingByAdmin,
  FetchActivity,
  GetDashboardConfiguration,
  GetCardSummary,
  GetBookingTypeForGlobalSearch,
  GetBookingGlobalSearch,
  ShowModifyField,
  ModifyBooking,
  ApprovalActionBooking,
  RemarkStatusDetails,
  GetActionByStatus,
  GetReasonByActions,
  AddReason,
  UpdateReason,
  CompleteBooking,
  RaiseDispute,
  GetBookingDumpTypeList,
  PostDownloadDumpAdminV2,
  GetDownloadDumpList,
  BulkBookingConfirmationByVendor,
});

export default rootReducer;
