import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Modal, ModalBody, ModalHeader, Row, Button, Spinner } from 'reactstrap';
import { DOWNLOAD_DUMP_ADMIN_V2, DOWNLOAD_DUMP_ADMIN_V2_EMPTY, GET_BOOKING_DUMP_TYPE_LIST } from '../../../../../../store/actions';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import { Error } from '../../../../../Common';
import { on } from 'events';

const DownloadModal = ({ onClose }) => {
  const dispatch = useDispatch();

  const getDefaultDates = () => {
    const today = new Date();
    const sixtyDaysAgo = new Date();
    sixtyDaysAgo.setDate(today.getDate() - 59);
    return [sixtyDaysAgo, today];
  };

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [selectBookingType, setSelectBookingType] = useState(null);
  const [isShowError, setIsShowError] = useState(false);
  const [selectedDates, setSelectedDates] = useState(getDefaultDates());
  const [maxDate, setMaxDate] = useState(getDefaultDates()[1]);

  console.log('selectedDates', selectedDates);

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { getBookingDumpType, getBookingDumpTypeLoading, postDownloadDumpV2Loading, postDownloadDumpV2 } = useSelector(state => ({
    getBookingDumpType: state?.GetBookingDumpTypeList?.data,
    getBookingDumpTypeLoading: state?.GetBookingDumpTypeList?.loading,

    postDownloadDumpV2: state?.PostDownloadDumpAdminV2?.data,
    postDownloadDumpV2Loading: state?.PostDownloadDumpAdminV2?.loading,
  }));

  useEffect(() => {
    dispatch({ type: GET_BOOKING_DUMP_TYPE_LIST });
  }, [dispatch]);

  useEffect(() => {
    if (postDownloadDumpV2) {
      onClose();
      dispatch({ type: DOWNLOAD_DUMP_ADMIN_V2_EMPTY });
    }
  }, [postDownloadDumpV2]);

  const inputSelectHandler = (label, data) => {
    if (label === `reason_id`) {
      setSelectBookingType(data);
    } else {
      setIsShowError(false);
    }
  };

  const formatDateToYMD = date => {
    if (!date) return null;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure two digits
    const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = dates => {
    if (!dates || dates.length === 0) return;

    const startDate = dates[0] ? new Date(dates[0]) : null;
    const endDate = dates[1] ? new Date(dates[1]) : null;

    const formattedStartDate = formatDateToYMD(startDate); // Now in local timezone
    const formattedEndDate = formatDateToYMD(endDate); // Now in local timezone

    console.log('startDate:', formattedStartDate);
    console.log('endDate:', formattedEndDate);

    if (startDate) {
      const newMaxDate = new Date(startDate);
      newMaxDate.setDate(newMaxDate.getDate() + 59);
      setMaxDate(formatDateToYMD(newMaxDate)); // Keep maxDate in "Y-m-d"
    }

    if (startDate && endDate) {
      const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays > 59) {
        // setSelectedDates(getDefaultDates());
      } else {
        setSelectedDates([formattedStartDate, formattedEndDate]);
      }
    }
  };

  const handleSubmit = () => {
    if (selectBookingType) {
      dispatch({
        type: DOWNLOAD_DUMP_ADMIN_V2,
        payload: {
          data: {
            dumpType: selectBookingType?.value,
            startDate: selectedDates[0],
            endDate: selectedDates[1],
          },
        },
      });
    } else {
      setIsShowError(true);
    }
  };

  return (
    <Modal isOpen={true} centered>
      <ModalHeader toggle={onClose}>Download Booking Dump</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={12} className="mb-3">
            <Select
              id="booking-dump-type"
              name="booking-dump-type"
              placeholder="Select the download type"
              value={selectBookingType}
              options={getBookingDumpType?.results?.map(remark => ({
                label: `${remark?.value}`,
                value: `${remark?.key}`,
              }))}
              onChange={data => inputSelectHandler(`reason_id`, data)}
            />
            {isShowError && selectBookingType === null && <Error text="Kindly select booking type" ref={null} />}
          </Col>
          <Col md={12} className="mb-3 d-flex justify-content-center download-date-range">
            <Flatpickr
              onChange={handleDateChange}
              className="form-control"
              value={selectedDates}
              options={{
                mode: 'range',
                dateFormat: 'Y-m-d',
                showMonths: 2, // Show two months in calendar
                defaultDate: getDefaultDates(),
                maxDate: maxDate,
                inline: true, // Show calendar directly (no input field)
                closeOnSelect: false, // Keep calendar open after selection
              }}
            />
          </Col>
        </Row>
      </ModalBody>

      <div className="d-flex justify-content-center mb-4 gap-3">
        {!postDownloadDumpV2Loading && (
          <Button color="primary" style={{ fontSize: '14px', width: '100px' }} onClick={handleSubmit}>
            Download
          </Button>
        )}

        {postDownloadDumpV2Loading && (
          <Button color="primary" style={{ fontSize: '14px', width: '100px' }}>
            <Spinner className="btn-replacer-spinner" />
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default DownloadModal;
