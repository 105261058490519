import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, umsUrl, adminAPIURL } from '../../../../Containers/Config/index.js';
import { DOWNLOAD_DUMP_ADMIN_V2, DOWNLOAD_DUMP_ADMIN_V2_SUCCESS, DOWNLOAD_DUMP_ADMIN_V2_ERROR } from '../actions';
import apiCall from '../../apiCall';
import cogoToast from 'cogo-toast';

function* postDownloadDumpAdminV2({ payload }) {
  try {
    const filteredHeaders = { ...customHeaders };
    delete filteredHeaders['X-Requested-By'];

    const response = yield call(apiCall, {
      url: `${adminAPIURL}/v2/downloads/booking-dump-request`,
      method: 'POST',
      data: payload?.data,
      headers: {
        // Authorization: Constant?.CLIENT_TOKEN,
        ...filteredHeaders,
        // withCredentials: true
      },
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({
        type: DOWNLOAD_DUMP_ADMIN_V2_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: DOWNLOAD_DUMP_ADMIN_V2_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: DOWNLOAD_DUMP_ADMIN_V2_ERROR, payload: error });
  }
}

export function* watchPostDownloadDumpAdminV2() {
  yield takeEvery(DOWNLOAD_DUMP_ADMIN_V2, postDownloadDumpAdminV2);
}

function* postDownloadDumpAdminV2Saga() {
  yield all([fork(watchPostDownloadDumpAdminV2)]);
}

export default postDownloadDumpAdminV2Saga;
