import React from 'react';
import { UserFrameIcon } from '../../../../../../Components/icons';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CountUp from 'react-countup';
import vendorAssign from '../../../../../../assets/custom/v2/vendor.svg';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { AiOutlinePercentage } from 'react-icons/ai';

const BookingTypeCard = ({ item, cardData, fromApprovalPendingCard, isModificationBooking }) => {
  const history = useHistory();

  const totalCount =
    cardData?.[item?.name]?.data?.totalCount || cardData?.[item?.name]?.data?.totalCount === 0
      ? Number(cardData?.[item?.name]?.data?.totalCount)
      : null;
  const routeHandler = () => {
    if (item?.nextNavigation) {
      if (fromApprovalPendingCard) {
        const params = {
          name: item?.name,
          type: item?.type,
          bookingType: 'approval-action',
          modification: isModificationBooking,
        };
        history.push({
          pathname: '/v2/booking/airport-transfer',
          search: `?${queryString.stringify(params)}`,
        });
      } else {
        const params = {
          nextNavigation: item?.nextNavigation,
          sectionFilter: item?.sectionFilter,
        };
        history.push({
          pathname: '/v2/booking-dashboard',
          search: `?${queryString.stringify(params)}`,
        });
      }
    }
  };

  console.log('change here', cardData?.[item?.name]?.data);

  return (
    <div className="d-flex justify-content-between sla-card" style={{ cursor: item?.nextNavigation && 'pointer' }} onClick={routeHandler}>
      <img src={item?.icon ? item?.icon : vendorAssign} className="img-fluid" alt="" style={{ maxWidth: '45px', height: '45px' }} />

      <div className="text-end">
        <p className="text-black text-format-7 m-0">{item?.displayName}</p>
        {totalCount != null ? (
          <CountUp separator="" start={0} end={totalCount} duration={2} className="fw-bold text-format-3 m-0" />
        ) : (
          <p className="fw-bold text-format-3 m-0">-</p> // Showing dash when data is missing
        )}
        {/* <p className="mb-0 text-color-light-grey justify-content-end gap-2 d-flex">
                <RateValue value={item?.data?.changePercentage} fill={item?.data?.changePercentageColor ?? ''} />
                <span>Since Yesterday</span>
              </p> */}
        {cardData?.[item?.name]?.data?.changePercentage !== null && cardData?.[item?.name]?.data?.changePercentage !== undefined && (
          <div className="d-flex justify-content-end align-items-center gap-3 mt-3">
            <div className="d-flex gap-2">
              {cardData?.[item?.name]?.data?.changePercentage > 0 ? (
                <>
                  {' '}
                  <span>
                    <FaArrowUp color={cardData?.[item?.name]?.data?.changePercentageColor || '#0AB39C'} />
                  </span>
                  <span style={{ marginTop: '1px' }}>
                    {' '}
                    <span style={{ color: cardData?.[item?.name]?.data?.changePercentageColor || '#0AB39C' }}>
                      {Math.abs(Number(cardData?.[item?.name]?.data?.changePercentage)).toFixed(2)}
                    </span>
                    <span>
                      <AiOutlinePercentage color={cardData?.[item?.name]?.data?.changePercentageColor || '#0AB39C'} />
                    </span>
                  </span>
                </>
              ) : cardData?.[item?.name]?.data?.changePercentage < 0 ? (
                <>
                  <span>
                    <FaArrowDown color={cardData?.[item?.name]?.data?.changePercentageColor || 'red'} />
                  </span>
                  <span style={{ marginTop: '1px' }}>
                    {' '}
                    <span style={{ color: cardData?.[item?.name]?.data?.changePercentageColor || 'red' }}>
                      {Math.abs(Number(cardData?.[item?.name]?.data?.changePercentage)).toFixed(2)}
                    </span>
                    <span>
                      <AiOutlinePercentage color={cardData?.[item?.name]?.data?.changePercentageColor || 'red'} />
                    </span>
                  </span>
                </>
              ) : (
                <p className="fs-12 m-0" style={{ color: '#0AB39C' }}>
                  No Change in Booking
                </p>
              )}
            </div>
            {cardData?.[item?.name]?.data?.changePercentage !== 0 && (
              <p className="fs-12 m-0" style={{ color: '#8898AA' }}>
                Since Yesterday
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BookingTypeCard;
