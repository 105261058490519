import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Card, CardBody, CardHeader, Col, Input, Label, Row } from 'reactstrap';

import { Constant } from '../../../../../../Helpers/constant';
import CardViewActiveImg from './../../../../../../../assets/custom/card-view-active.png';
import CardViewImg from './../../../../../../../assets/custom/card-view.png';
import TabularViewActiveImg from './../../../../../../../assets/custom/tabular-view-active.png';
import TabularViewImg from './../../../../../../../assets/custom/tabular-view.png';
import CardView from './CardView';
import TabularView from './TabularView';
import { GET_BOOKING_DETAILS_EMPTY } from '../../../../../../../store/actions';

const DataViews = ({
  data,
  titleText,
  updateURLParams,
  skip,
  limit,
  setCurrentSelectedBooking,
  selectedBooking,
  setCurrentActiveBookingAction,
  bookingType = '',
  appliedName = '',
  isMultiSelectable = false,
  setIsMultiSelectable = null,
  shouldHaveClearSelection = false,
  setShouldHaveClearSelection,
  filterSubmitHandler,
  currentlyAppliedFilters,
  assignedToMeFilter,
  setAssignedToMeFilter,
  secondaryFiltersList,
}) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATE ---------------------------- */
  const [isTabularView, setIsTabularView] = useState(
    Constant?.userTypes?.VENDOR_USER_V2 === parseInt(localStorage.getItem('userType')) ? false : true,
  );

  useEffect(() => {
    if (data?.result?.length > 0 && Constant?.userTypes?.VENDOR_USER_V2 === parseInt(localStorage.getItem('userType'))) {
      if (data?.result?.[0]?.actions?.includes('complete_booking') || data?.result?.[0]?.actions?.includes('vendor_accept')) {
        setIsTabularView(true);
        setIsMultiSelectable(true);
      } else {
        setIsTabularView(false);
        setIsMultiSelectable(false);
      }
    }
  }, [data?.result]);

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const handleRowsPerPageChange = newRowsPerPage => {
    updateURLParams({ skip: 1, limit: newRowsPerPage });
  };

  const paginationHandler = pageNumber => {
    updateURLParams({ skip: pageNumber + 1, limit: limit });
  };

  const toggleView = () => {
    setIsTabularView(!isTabularView);
    setCurrentSelectedBooking(null);
    setCurrentActiveBookingAction(null);

    setShouldHaveClearSelection(true);
    setTimeout(() => {
      setShouldHaveClearSelection(false);
    }, 1000);

    updateURLParams({ skip: 1, limit: Constant?.TABLE_PAGE_SIZE });
    dispatch({
      type: GET_BOOKING_DETAILS_EMPTY,
    });
  };

  console.log('assignedToMeFilter-', assignedToMeFilter);

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex justify-content-end">
              <h4 className="card-title mb-0 flex-grow-1 custom-card-title"> {titleText} </h4>

              <div className="d-flex gap-3">
                {isTabularView && (
                  <div className="d-flex gap-2">
                    {/* {!selectedBooking && data?.result?.[0]?.actions?.includes('assign_to_me') && ( */}
                    {!selectedBooking && secondaryFiltersList?.isShowUnassignedFilter && (
                      <>
                        <div className="form-switch d-flex align-items-center gap-2">
                          <Label htmlFor="unassigned-filter" className="form-label mb-0">
                            Show Unassigned Bookings
                          </Label>
                          <Input
                            className="form-check-input code-switcher form-switch-md cursor-pointer m-auto d-block"
                            type="checkbox"
                            id="unassigned-filter"
                            name="unassigned-filter"
                            checked={assignedToMeFilter}
                            onChange={e => {
                              setAssignedToMeFilter(e.target.checked);
                              filterSubmitHandler({ ...currentlyAppliedFilters, is_show_unassigned_bookings: e.target.checked });
                            }}
                          />
                        </div>
                      </>
                    )}

                    <>
                      {selectedBooking?.length === 1 && selectedBooking?.[0]?.actions?.includes('approve') && (
                        <Button className="customized-btn" onClick={() => setCurrentActiveBookingAction('approval')} color="primary">
                          Approve
                        </Button>
                      )}
                      {selectedBooking?.length === 1 && selectedBooking?.[0]?.actions?.includes('reject') && (
                        <Button className="customized-btn reject" onClick={() => setCurrentActiveBookingAction('reject')} color="primary">
                          Reject
                        </Button>
                      )}
                      {selectedBooking?.length === 1 && selectedBooking?.[0]?.actions?.includes('assign_to_me') && (
                        <Button className="customized-btn" onClick={() => setCurrentActiveBookingAction('assign_to_me')} color="primary">
                          Assign to Me
                        </Button>
                      )}
                      {selectedBooking?.length === 1 && selectedBooking?.[0]?.actions?.includes('assign_to_vendor') && (
                        <Button className="customized-btn" onClick={() => setCurrentActiveBookingAction('assign_to_vendor')} color="success">
                          Assign to Vendor
                        </Button>
                      )}
                      {selectedBooking?.length === 1 && selectedBooking?.[0]?.actions?.includes('reassign_vendor') && (
                        <Button className="customized-btn" onClick={() => setCurrentActiveBookingAction('reassign_vendor')} color="primary">
                          Reassign Vendor
                        </Button>
                      )}
                      {selectedBooking?.length === 1 && selectedBooking?.[0]?.actions?.includes('assign_chauffeur') && (
                        <Button className="customized-btn" onClick={() => setCurrentActiveBookingAction('assign_chauffeur')} color="success">
                          Assign Chauffeur
                        </Button>
                      )}
                      {selectedBooking?.length === 1 && selectedBooking?.[0]?.actions?.includes('change_chauffeur') && (
                        <Button className="customized-btn" onClick={() => setCurrentActiveBookingAction('change_chauffeur')} color="success">
                          Change Chauffeur
                        </Button>
                      )}
                      {selectedBooking?.length === 1 && selectedBooking?.[0]?.actions?.includes('complete_booking') && (
                        <Button className="customized-btn" onClick={() => setCurrentActiveBookingAction('complete_booking')} color="success">
                          Mark As Complete
                        </Button>
                      )}
                      {selectedBooking?.length === 1 && selectedBooking?.[0]?.actions?.includes('vendor_accept') && (
                        <Button className="customized-btn" onClick={() => setCurrentActiveBookingAction('vendor_accept')} color="success">
                          Confirm
                        </Button>
                      )}
                      {selectedBooking?.length === 1 && selectedBooking?.[0]?.actions?.includes('vendor_declined') && (
                        <Button className="customized-btn" onClick={() => setCurrentActiveBookingAction('vendor_declined')} color="danger">
                          Decline
                        </Button>
                      )}
                      {selectedBooking?.length === 1 && selectedBooking?.[0]?.actions?.includes('raise_a_dispute') && (
                        <Button className="customized-btn" onClick={() => setCurrentActiveBookingAction('raise_a_dispute')} color="danger">
                          Raise Dispute
                        </Button>
                      )}

                      {/* ********************** MULTIPLE SELECTION ACTIONS LIST - START ********************** */}
                      {isMultiSelectable && selectedBooking?.length > 1 && selectedBooking?.[0]?.actions?.includes('complete_booking') && (
                        <Button className="customized-btn" onClick={() => setCurrentActiveBookingAction('complete_booking')} color="success">
                          Mark All As Complete ({selectedBooking?.length ?? 0})
                        </Button>
                      )}

                      {isMultiSelectable && selectedBooking?.length > 1 && selectedBooking?.[0]?.actions?.includes('vendor_accept') && (
                        <Button className="customized-btn" onClick={() => setCurrentActiveBookingAction('vendor_accept')} color="success">
                          Confirm All Booking ({selectedBooking?.length ?? 0})
                        </Button>
                      )}
                      {/* ********************** MULTIPLE SELECTION ACTIONS LIST - END ********************** */}
                    </>
                  </div>
                )}

                <div className="d-flex gap-2 align-items-center">
                  {isTabularView ? (
                    <img src={CardViewImg} className="view-img" onClick={toggleView} />
                  ) : (
                    <img src={CardViewActiveImg} className="view-img" onClick={toggleView} />
                  )}
                  {isTabularView ? (
                    <img src={TabularViewActiveImg} className="view-img" onClick={toggleView} />
                  ) : (
                    <img src={TabularViewImg} className="view-img" onClick={toggleView} />
                  )}
                </div>
              </div>
            </CardHeader>

            <CardBody
              style={{
                zIndex: 0,
              }}
            >
              {isTabularView ? (
                <TabularView
                  data={data?.result}
                  totalCount={data?.totalCount}
                  skip={skip}
                  limit={limit}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  handlePageChange={paginationHandler}
                  setCurrentSelectedBooking={setCurrentSelectedBooking}
                  selectedBooking={selectedBooking}
                  isTableDisplay={true}
                  bookingType={bookingType}
                  appliedName={appliedName}
                  isMultiSelectable={isMultiSelectable}
                  shouldHaveClearSelection={shouldHaveClearSelection}
                />
              ) : (
                <>
                  <CardView
                    data={data?.result}
                    isMultiSelectable={isMultiSelectable}
                    currentSelectedBooking={selectedBooking}
                    setCurrentSelectedBooking={setCurrentSelectedBooking}
                    setCurrentActiveBookingAction={setCurrentActiveBookingAction}
                  />
                  <TabularView
                    data={data?.result}
                    totalCount={data?.totalCount}
                    skip={skip}
                    limit={limit}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    handlePageChange={paginationHandler}
                    setCurrentSelectedBooking={setCurrentSelectedBooking}
                    isTableDisplay={(data?.result?.length ?? 0) === 0 ? true : false}
                    bookingType={bookingType}
                    isMultiSelectable={isMultiSelectable}
                    shouldHaveClearSelection={shouldHaveClearSelection}
                  />
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DataViews;
