/* ---------------------- BOOKINGS V2 - AIRPORT TRANSFER ---------------------- */

/* ----------------- ASSIGN CHAUFFEUR ----------------- */
export const ASSIGN_CHAUFFEUR = `ASSIGN_CHAUFFEUR`;
export const ASSIGN_CHAUFFEUR_SUCCESS = `ASSIGN_CHAUFFEUR_SUCCESS`;
export const ASSIGN_CHAUFFEUR_ERROR = `ASSIGN_CHAUFFEUR_ERROR`;
export const ASSIGN_CHAUFFEUR_EMPTY = `ASSIGN_CHAUFFEUR_EMPTY`;

/* ----------------- BOOKING ASSIGN TO ME ----------------- */
export const BOOKING_ASSIGN_TO_ME = `BOOKING_ASSIGN_TO_ME`;
export const BOOKING_ASSIGN_TO_ME_SUCCESS = `BOOKING_ASSIGN_TO_ME_SUCCESS`;
export const BOOKING_ASSIGN_TO_ME_ERROR = `BOOKING_ASSIGN_TO_ME_ERROR`;
export const BOOKING_ASSIGN_TO_ME_EMPTY = `BOOKING_ASSIGN_TO_ME_EMPTY`;

/* ----------------- ASSIGN VENDOR ----------------- */
export const ASSIGN_VENDOR = `ASSIGN_VENDOR`;
export const ASSIGN_VENDOR_SUCCESS = `ASSIGN_VENDOR_SUCCESS`;
export const ASSIGN_VENDOR_ERROR = `ASSIGN_VENDOR_ERROR`;
export const ASSIGN_VENDOR_EMPTY = `ASSIGN_VENDOR_EMPTY`;

/* ----------------- CANCEL BOOKING BY VENDOR ----------------- */
export const CANCEL_BOOKING_BY_VENDOR = `CANCEL_BOOKING_BY_VENDOR`;
export const CANCEL_BOOKING_BY_VENDOR_SUCCESS = `CANCEL_BOOKING_BY_VENDOR_SUCCESS`;
export const CANCEL_BOOKING_BY_VENDOR_ERROR = `CANCEL_BOOKING_BY_VENDOR_ERROR`;
export const CANCEL_BOOKING_BY_VENDOR_EMPTY = `CANCEL_BOOKING_BY_VENDOR_EMPTY`;

/* ----------------- CANCELLATION REMARKS FOR VENDOR ----------------- */
export const CANCELLATION_REMARKS_FOR_VENDOR = `CANCELLATION_REMARKS_FOR_VENDOR`;
export const CANCELLATION_REMARKS_FOR_VENDOR_SUCCESS = `CANCELLATION_REMARKS_FOR_VENDOR_SUCCESS`;
export const CANCELLATION_REMARKS_FOR_VENDOR_ERROR = `CANCELLATION_REMARKS_FOR_VENDOR_ERROR`;
export const CANCELLATION_REMARKS_FOR_VENDOR_EMPTY = `CANCELLATION_REMARKS_FOR_VENDOR_EMPTY`;

/* ----------------- CONFRIM BOOKING BY VENDOR ----------------- */
export const CONFIRM_BOOKING_BY_VENDOR = `CONFIRM_BOOKING_BY_VENDOR`;
export const CONFIRM_BOOKING_BY_VENDOR_SUCCESS = `CONFIRM_BOOKING_BY_VENDOR_SUCCESS`;
export const CONFIRM_BOOKING_BY_VENDOR_ERROR = `CONFIRM_BOOKING_BY_VENDOR_ERROR`;
export const CONFIRM_BOOKING_BY_VENDOR_EMPTY = `CONFIRM_BOOKING_BY_VENDOR_EMPTY`;

/* ----------------- BULK CONFRIM BOOKING BY VENDOR ----------------- */
export const BULK_CONFIRM_BOOKING_BY_VENDOR = `BULK_CONFIRM_BOOKING_BY_VENDOR`;
export const BULK_CONFIRM_BOOKING_BY_VENDOR_SUCCESS = `BULK_CONFIRM_BOOKING_BY_VENDOR_SUCCESS`;
export const BULK_CONFIRM_BOOKING_BY_VENDOR_ERROR = `BULK_CONFIRM_BOOKING_BY_VENDOR_ERROR`;
export const BULK_CONFIRM_BOOKING_BY_VENDOR_EMPTY = `BULK_CONFIRM_BOOKING_BY_VENDOR_EMPTY`;

/* ----------------- CREATE CHAUFFEUR ----------------- */
export const CREATE_CHAUFFEUR = `CREATE_CHAUFFEUR`;
export const CREATE_CHAUFFEUR_SUCCESS = `CREATE_CHAUFFEUR_SUCCESS`;
export const CREATE_CHAUFFEUR_ERROR = `CREATE_CHAUFFEUR_ERROR`;
export const CREATE_CHAUFFEUR_EMPTY = `CREATE_CHAUFFEUR_EMPTY`;

/* ----------------- GET CARS LIST OF VENDOR ----------------- */
export const GET_CARS_LIST_OF_VENDOR = `GET_CARS_LIST_OF_VENDOR`;
export const GET_CARS_LIST_OF_VENDOR_SUCCESS = `GET_CARS_LIST_OF_VENDOR_SUCCESS`;
export const GET_CARS_LIST_OF_VENDOR_ERROR = `GET_CARS_LIST_OF_VENDOR_ERROR`;
export const GET_CARS_LIST_OF_VENDOR_EMPTY = `GET_CARS_LIST_OF_VENDOR_EMPTY`;

/* ----------------- GET CHAUFFEURS LIST ----------------- */
export const GET_CHAUFFEURS_LIST = `GET_CHAUFFEURS_LIST`;
export const GET_CHAUFFEURS_LIST_SUCCESS = `GET_CHAUFFEURS_LIST_SUCCESS`;
export const GET_CHAUFFEURS_LIST_ERROR = `GET_CHAUFFEURS_LIST_ERROR`;
export const GET_CHAUFFEURS_LIST_EMPTY = `GET_CHAUFFEURS_LIST_EMPTY`;

/* ----------------- GET VENDORS LIST DATA ----------------- */
export const GET_VENDORS_LIST_DATA = `GET_VENDORS_LIST_DATA`;
export const GET_VENDORS_LIST_DATA_SUCCESS = `GET_VENDORS_LIST_DATA_SUCCESS`;
export const GET_VENDORS_LIST_DATA_ERROR = `GET_VENDORS_LIST_DATA_ERROR`;
export const GET_VENDORS_LIST_DATA_EMPTY = `GET_VENDORS_LIST_DATA_EMPTY`;

/* ----------------- ASSIGN VENDOR REMARKS LIST ----------------- */
export const ASSIGN_VENDOR_REMARKS_LIST = `ASSIGN_VENDOR_REMARKS_LIST`;
export const ASSIGN_VENDOR_REMARKS_LIST_SUCCESS = `ASSIGN_VENDOR_REMARKS_LIST_SUCCESS`;
export const ASSIGN_VENDOR_REMARKS_LIST_ERROR = `ASSIGN_VENDOR_REMARKS_LIST_ERROR`;
export const ASSIGN_VENDOR_REMARKS_LIST_EMPTY = `ASSIGN_VENDOR_REMARKS_LIST_EMPTY`;

/* ----------------- GET BOOKINGS LIST ----------------- */
export const GET_BOOKINGS_LIST = `GET_BOOKINGS_LIST`;
export const GET_BOOKINGS_LIST_SUCCESS = `GET_BOOKINGS_LIST_SUCCESS`;
export const GET_BOOKINGS_LIST_ERROR = `GET_BOOKINGS_LIST_ERROR`;
export const GET_BOOKINGS_LIST_EMPTY = `GET_BOOKINGS_LIST_EMPTY`;

/* ----------------- GET BOOKING DETAILS ----------------- */
export const GET_BOOKING_DETAILS = `GET_BOOKING_DETAILS`;
export const GET_BOOKING_DETAILS_SUCCESS = `GET_BOOKING_DETAILS_SUCCESS`;
export const GET_BOOKING_DETAILS_ERROR = `GET_BOOKING_DETAILS_ERROR`;
export const GET_BOOKING_DETAILS_EMPTY = `GET_BOOKING_DETAILS_EMPTY`;

/* ----------------- GET FILTERS LIST ----------------- */
export const GET_FILTERS_LIST = `GET_FILTERS_LIST`;
export const GET_FILTERS_LIST_SUCCESS = `GET_FILTERS_LIST_SUCCESS`;
export const GET_FILTERS_LIST_ERROR = `GET_FILTERS_LIST_ERROR`;
export const GET_FILTERS_LIST_EMPTY = `GET_FILTERS_LIST_EMPTY`;

/* ----------------- GET FILTER VALUES ----------------- */
export const GET_FILTER_VALUES = `GET_FILTER_VALUES`;
export const GET_FILTER_VALUES_SUCCESS = `GET_FILTER_VALUES_SUCCESS`;
export const GET_FILTER_VALUES_ERROR = `GET_FILTER_VALUES_ERROR`;
export const GET_FILTER_VALUES_EMPTY = `GET_FILTER_VALUES_EMPTY`;

// GET BOOKING TYPE
export const GET_BOOKING_TYPE = `GET_BOOKING_TYPE`;
export const GET_BOOKING_TYPE_SUCCESS = `GET_BOOKING_TYPE_SUCCESS`;
export const GET_BOOKING_TYPE_ERROR = `GET_BOOKING_TYPE_ERROR`;
export const GET_BOOKING_TYPE_EMPTY = `GET_BOOKING_TYPE_EMPTY`;

// GET V2 DASHBOARD
export const GET_DASHBOARD = `GET_DASHBOARD`;
export const GET_DASHBOARD_SUCCESS = `GET_DASHBOARD_SUCCESS`;
export const GET_DASHBOARD_ERROR = `GET_DASHBOARD_ERROR`;
export const GET_DASHBOARD_EMPTY = `GET_DASHBOARD_EMPTY`;

// GET USER ROLES
export const GET_USER_ROLES = `GET_USER_ROLES`;
export const GET_USER_ROLES_SUCCESS = `GET_USER_ROLES_SUCCESS`;
export const GET_USER_ROLES_ERROR = `GET_USER_ROLES_ERROR`;
export const GET_USER_ROLES_EMPTY = `GET_USER_ROLES_EMPTY`;

// GET CARD DETAILS
export const GET_CARD_DETAILS = `GET_CARD_DETAILS`;
export const GET_CARD_DETAILS_SUCCESS = `GET_CARD_DETAILS_SUCCESS`;
export const GET_CARD_DETAILS_ERROR = `GET_CARD_DETAILS_ERROR`;
export const GET_CARD_DETAILS_EMPTY = `GET_CARD_DETAILS_EMPTY`;

/* ----------------- CANCEL BOOKING BY ADMIN ----------------- */
export const CANCEL_BOOKING_BY_ADMIN = `CANCEL_BOOKING_BY_ADMIN`;
export const CANCEL_BOOKING_BY_ADMIN_SUCCESS = `CANCEL_BOOKING_BY_ADMIN_SUCCESS`;
export const CANCEL_BOOKING_BY_ADMIN_ERROR = `CANCEL_BOOKING_BY_ADMIN_ERROR`;
export const CANCEL_BOOKING_BY_ADMIN_EMPTY = `CANCEL_BOOKING_BY_ADMIN_EMPTY`;

/* ----------------- GET BOOKING TYPE FOR GLOBAL SEARCH ----------------- */
export const GET_BOOKING_TYPE_FOR_GLOBAL_SEARCH = `GET_BOOKING_TYPE_FOR_GLOBAL_SEARCH`;
export const GET_BOOKING_TYPE_FOR_GLOBAL_SEARCH_SUCCESS = `GET_BOOKING_TYPE_FOR_GLOBAL_SEARCH_SUCCESS`;
export const GET_BOOKING_TYPE_FOR_GLOBAL_SEARCH_ERROR = `GET_BOOKING_TYPE_FOR_GLOBAL_SEARCH_ERROR`;
export const GET_BOOKING_TYPE_FOR_GLOBAL_SEARCH_EMPTY = `GET_BOOKING_TYPE_FOR_GLOBAL_SEARCH_EMPTY`;

/* ----------------- GET BOOKING FOR GLOBAL SEARCH ----------------- */
export const GET_BOOKING_GLOBAL_SEARCH = `GET_BOOKING_GLOBAL_SEARCH`;
export const GET_BOOKING_GLOBAL_SEARCH_SUCCESS = `GET_BOOKING_GLOBAL_SEARCH_SUCCESS`;
export const GET_BOOKING_GLOBAL_SEARCH_ERROR = `GET_BOOKING_GLOBAL_SEARCH_ERROR`;
export const GET_BOOKING_GLOBAL_SEARCH_EMPTY = `GET_BOOKING_GLOBAL_SEARCH_EMPTY`;

/* ----------------- MODIFY FIELDS ----------------- */
export const SHOW_MODIFY_FIELDS = `SHOW_MODIFY_FIELDS`;
export const SHOW_MODIFY_FIELDS_SUCCESS = `SHOW_MODIFY_FIELDS_SUCCESS`;
export const SHOW_MODIFY_FIELDS_ERROR = `SHOW_MODIFY_FIELDS_ERROR`;
export const SHOW_MODIFY_FIELDS_EMPTY = `SHOW_MODIFY_FIELDS_EMPTY`;

/* ----------------- MODIFY BOOKING ----------------- */
export const MODIFY_BOOKING = `MODIFY_BOOKING`;
export const MODIFY_BOOKING_SUCCESS = `MODIFY_BOOKING_SUCCESS`;
export const MODIFY_BOOKING_ERROR = `MODIFY_BOOKING_ERROR`;
export const MODIFY_BOOKING_EMPTY = `MODIFY_BOOKING_EMPTY`;

/* ----------------- APPROVAL AWAITING ----------------- */
export const APPROVAL_ACTION = `APPROVAL_ACTION`;
export const APPROVAL_ACTION_SUCCESS = `APPROVAL_ACTION_SUCCESS`;
export const APPROVAL_ACTION_ERROR = `APPROVAL_ACTION_ERROR`;
export const APPROVAL_ACTION_EMPTY = `APPROVAL_ACTION_EMPTY`;

/* ----------------- REMARKS STATUS ----------------- */
export const REMARK_STATUS = `REMARK_STATUS`;
export const REMARK_STATUS_SUCCESS = `REMARK_STATUS_SUCCESS`;
export const REMARK_STATUS_ERROR = `REMARK_STATUS_ERROR`;
export const REMARK_STATUS_EMPTY = `REMARK_STATUS_EMPTY`;

/* ----------------- REMARKS STATUS ----------------- */
export const GET_ACTION_BY_STATUS = `GET_ACTION_BY_STATUS`;
export const GET_ACTION_BY_STATUS_SUCCESS = `GET_ACTION_BY_STATUS_SUCCESS`;
export const GET_ACTION_BY_STATUS_ERROR = `GET_ACTION_BY_STATUS_ERROR`;
export const GET_ACTION_BY_STATUS_EMPTY = `GET_ACTION_BY_STATUS_EMPTY`;

/* ----------------- REMARKS STATUS ----------------- */
export const GET_REASON_BY_ACTIONS = `GET_REASON_BY_ACTIONS`;
export const GET_REASON_BY_ACTIONS_SUCCESS = `GET_REASON_BY_ACTIONS_SUCCESS`;
export const GET_REASON_BY_ACTIONS_ERROR = `GET_REASON_BY_ACTIONS_ERROR`;
export const GET_REASON_BY_ACTIONS_EMPTY = `GET_REASON_BY_ACTIONS_EMPTY`;

/* ----------------- REMARKS STATUS ----------------- */
export const ADD_REASON = `ADD_REASON`;
export const ADD_REASON_SUCCESS = `ADD_REASON_SUCCESS`;
export const ADD_REASON_ERROR = `ADD_REASON_ERROR`;
export const ADD_REASON_EMPTY = `ADD_REASON_EMPTY`;

/* ----------------- REMARKS STATUS ----------------- */
export const UPDATE_REASON = `UPDATE_REASON`;
export const UPDATE_REASON_SUCCESS = `UPDATE_REASON_SUCCESS`;
export const UPDATE_REASON_ERROR = `UPDATE_REASON_ERROR`;
export const UPDATE_REASON_EMPTY = `UPDATE_REASON_EMPTY`;

/* ----------------- COMPLETE BOOKING ----------------- */
export const COMPLETE_BOOKING = `COMPLETE_BOOKING`;
export const COMPLETE_BOOKING_SUCCESS = `COMPLETE_BOOKING_SUCCESS`;
export const COMPLETE_BOOKING_ERROR = `COMPLETE_BOOKING_ERROR`;
export const COMPLETE_BOOKING_EMPTY = `COMPLETE_BOOKING_EMPTY`;

/* ----------------- Raise Dispute ----------------- */
export const RAISE_DISPUTE = `RAISE_DISPUTE`;
export const RAISE_DISPUTE_SUCCESS = `RAISE_DISPUTE_SUCCESS`;
export const RAISE_DISPUTE_ERROR = `RAISE_DISPUTE_ERROR`;
export const RAISE_DISPUTE_EMPTY = `RAISE_DISPUTE_EMPTY`;

/* ----------------- Booking Dump Type List ----------------- */
export const GET_BOOKING_DUMP_TYPE_LIST = `GET_BOOKING_DUMP_TYPE_LIST`;
export const GET_BOOKING_DUMP_TYPE_LIST_SUCCESS = `GET_BOOKING_DUMP_TYPE_LIST_SUCCESS`;
export const GET_BOOKING_DUMP_TYPE_LIST_ERROR = `GET_BOOKING_DUMP_TYPE_LIST_ERROR`;
export const GET_BOOKING_DUMP_TYPE_LIST_EMPTY = `GET_BOOKING_DUMP_TYPE_LIST_EMPTY`;

/* ----------------- Post Download Dump ----------------- */
export const DOWNLOAD_DUMP_ADMIN_V2 = `DOWNLOAD_DUMP_ADMIN_V2`;
export const DOWNLOAD_DUMP_ADMIN_V2_SUCCESS = `DOWNLOAD_DUMP_ADMIN_V2_SUCCESS`;
export const DOWNLOAD_DUMP_ADMIN_V2_ERROR = `DOWNLOAD_DUMP_ADMIN_V2_ERROR`;
export const DOWNLOAD_DUMP_ADMIN_V2_EMPTY = `DOWNLOAD_DUMP_ADMIN_V2_EMPTY`;

/* ----------------- Get Download Dump ----------------- */
export const GET_DOWNLOAD_DUMP_ADMIN_V2 = `GET_DOWNLOAD_DUMP_ADMIN_V2`;
export const GET_DOWNLOAD_DUMP_ADMIN_V2_SUCCESS = `GET_DOWNLOAD_DUMP_ADMIN_V2_SUCCESS`;
export const GET_DOWNLOAD_DUMP_ADMIN_V2_ERROR = `GET_DOWNLOAD_DUMP_ADMIN_V2_ERROR`;
export const GET_DOWNLOAD_DUMP_ADMIN_V2_EMPTY = `GET_DOWNLOAD_DUMP_ADMIN_V2_EMPTY`;
