import { useEffect, useState } from 'react';

// import { Constant } from '../../../../../../Helpers/constant';
import { Constant } from '../../../../../Helpers/constant';

import { Input } from 'reactstrap';
// import HotClock from './../../../../../../../assets/custom/hot-clock.png';
// import SufficientClock from './../../../../../../../assets/custom/sufficient-clock.png';
// import WarningClock from './../../../../../../../assets/custom/warning-clock.png';
import CustomizedTable from '../../../Bookings/AirportTransfer/Components/Views/CustomizedTable';
import editIcon from '../../../../../../assets/custom/edit-icon.svg';
import { useDispatch } from 'react-redux';
import { UPDATE_REASON } from '../../../../../../store/actions';
// import CustomizedTable from './CustomizedTable';
const TabularView = ({ data, totalCount, skip, limit, handleRowsPerPageChange, handlePageChange, actionBtnHandler, isTableDisplay }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [bookingsData, setBookingsData] = useState([]);
  const [columns, setColumns] = useState([]);

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    window.open(params?.fileLink, `_blank`);
  };

  const formatDateTime = dateString => {
    if (!dateString) return '';

    const date = new Date(dateString);

    return date
      .toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      })
      .replace(',', '');
  };

  useEffect(() => {
    if (data?.length > 0) {
      const fetchColumns = [
        {
          name: 'S.No',
          width: '70px',
          wrap: true,
          selector: row => row?.serial_number,
        },
        {
          name: 'Request Date Time',
          minWidth: '200px',
          cell: row => <span>{formatDateTime(row?.requestedAt)}</span>,
        },
        {
          name: 'Report Type',
          minWidth: '200px',
          cell: row => <span>{row?.reportType}</span>,
        },
        {
          name: 'Booking Count',
          minWidth: '200px',
          cell: row => <span>{row?.bookingCount}</span>,
        },
        {
          name: 'Date Range',
          minWidth: '200px',
          cell: row => <span>{row?.dateRange}</span>,
        },
        {
          name: 'Booking Status',
          minWidth: '200px',
          cell: row => <span>{row?.bookingStatus}</span>,
        },
        {
          name: 'Dump Status',
          minWidth: '200px',
          cell: row => <span>{row?.dumpStatus}</span>,
        },
        {
          name: 'Action',
          width: '250px',
          cell: row => (
            <>
              <div
                className="d-flex justify-content-center gap-2"
                onClick={() => (row?.fileLink ? tableActionHandler(row) : null)}
                style={{
                  cursor: row?.fileLink ? 'pointer' : 'default',
                  textDecorationLine: row?.fileLink ? 'underline' : 'none',
                  color: row?.fileLink ? 'blue' : 'gray',
                }}
              >
                Click Here
              </div>
            </>
          ),
        },
      ];
      setColumns(fetchColumns);

      setBookingsData(
        data?.map((bookingData, index) => {
          const serial_number = Number(limit || 0) * Number(skip || 0) + (index + 1);
          return {
            serial_number: serial_number,
            ...bookingData,
          };
        }),
      );
    } else {
      setBookingsData([]);
      setColumns([]);
    }
  }, [data]);

  return (
    <CustomizedTable
      columns={columns ?? []}
      data={bookingsData ?? []}
      totalCount={totalCount}
      limit={limit}
      handleRowsPerPageChange={handleRowsPerPageChange}
      currentPage={skip}
      handlePageChange={handlePageChange}
      indexType={Constant.INDEX_TYPE.ZERO_BASED}
      isTableDisplay={isTableDisplay}
    />
  );
};

export default TabularView;
