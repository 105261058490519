import { useEffect, useState } from 'react';

import { Constant } from '../../../../../../Helpers/constant';

import { useDispatch } from 'react-redux';
import { GET_BOOKING_DETAILS_EMPTY } from '../../../../../../../store/actions';
import HotClock from './../../../../../../../assets/custom/hot-clock.png';
import SufficientClock from './../../../../../../../assets/custom/sufficient-clock.png';
import WarningClock from './../../../../../../../assets/custom/warning-clock.png';
import CustomizedTable from './CustomizedTable';

const TabularView = ({
  data,
  totalCount,
  skip,
  limit,
  handleRowsPerPageChange,
  handlePageChange,
  setCurrentSelectedBooking,
  selectedBooking,
  isTableDisplay,
  bookingType,
  appliedName = '',
  isMultiSelectable,
  shouldHaveClearSelection,
}) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [bookingsData, setBookingsData] = useState([]);
  const [columns, setColumns] = useState([]);

  const handleRowSelected = state => {
    setCurrentSelectedBooking(state?.selectedRows);
  };

  const checkboxHandler = rowData => {
    if (!isMultiSelectable) {
      if (selectedBooking?.[0]?.id === rowData?.id) {
        dispatch({
          type: GET_BOOKING_DETAILS_EMPTY,
        });

        setCurrentSelectedBooking(null);
      } else {
        setCurrentSelectedBooking([{ ...rowData }]);
      }
    }
  };

  useEffect(() => {
    if (data?.length > 0) {
      const fetchColumns = [
        ...(!isMultiSelectable
          ? [
              {
                name: '',
                width: '50px',
                selector: row => row?.serial_number,
                cell: row => (
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    // checked={row?.bookingId === selectedBooking?.bookingId}
                    checked={selectedBooking?.filter(booking => booking?.bookingId === row?.bookingId)?.length > 0}
                    onChange={() => checkboxHandler(row?.originalData)}
                  />
                ),
                omit: isMultiSelectable,
              },
            ]
          : []),
        ,
        {
          name: 'S.No',
          width: '70px',
          wrap: true,
          selector: row => row?.serial_number,
        },
        {
          name: 'Booking ID',
          width: '140px',
          cell: row =>
            isMultiSelectable ? (
              <div className="d-flex align-items-center gap-1">
                <span>{row?.bookingId ?? ''}</span>
              </div>
            ) : (
              <span
                className="cursor-pointer text-decoration-underline"
                style={{ color: row?.rowColorCode ? row?.rowColorCode : '#3577f1' }}
                onClick={() => setCurrentSelectedBooking([{ ...row?.originalData }])}
              >
                {row?.bookingId}
              </span>
            ),
        },

        {
          name: 'Agent Name',
          width: '150px',
          cell: row => (
            <div className="d-flex align-items-center gap-1">
              <span>{row?.assignedAgentName ?? ''}</span>
            </div>
          ),
        },

        // ...(data?.[0]?.actions?.includes('assign_to_vendor')
        //   ? [
        //       {
        //         name: 'Agent Name',
        //         width: '150px',
        //         cell: row => (
        //           <div className="d-flex align-items-center gap-1">
        //             <span>{row?.assignedAgentName ?? ''}</span>
        //           </div>
        //         ),
        //       },
        //     ]
        //   : []),

        {
          name: 'Vendor Name',
          width: '150px',
          cell: row => (
            <div className="d-flex align-items-center gap-1">
              <span>{`${row?.vendorName}`}</span>
            </div>
          ),
        },
        {
          name: 'TAT Remaining',
          width: '130px',
          cell: row => (
            <div className="d-flex align-items-center gap-1">
              {row?.tat?.color === '#FCE2E2' && <img src={HotClock} className="tabular-remaining-clock" />}
              {row?.tat?.color === '#FFF5E7' && <img src={WarningClock} className="tabular-remaining-clock" />}
              {row?.tat?.color === '#E5EBFF' && <img src={SufficientClock} className="tabular-remaining-clock" />}

              <span>{`${row?.tat?.value} ${row?.tat?.unit}`}</span>
            </div>
          ),
        },
        {
          name: 'Service Date & Time',
          width: '180px',
          cell: row => row?.serviceDateTime,
        },
        {
          name: 'Region',
          width: '100px',
          cell: row => row?.region,
        },
        {
          name: 'Zone',
          minWidth: '150px',
          cell: row => row?.zone,
        },
        {
          name: 'Pick Up Location',
          minWidth: '300px',
          cell: row => row?.pickupLocation,
        },
        {
          name: 'Drop Location',
          minWidth: '350px',
          cell: row => row?.dropLocation,
        },

        ...(bookingType === 'Approval Action'
          ? [
              {
                name: 'Status',
                width: '250px',
                cell: row => row?.status,
              },
            ]
          : []),
      ];
      setColumns(fetchColumns);

      setBookingsData(
        data?.map((bookingData, index) => {
          const serial_number = Number(limit || 0) * Number(skip || 0) + (index + 1);

          return {
            serial_number: serial_number,
            ...bookingData,
            serviceDateTime: bookingData?.serviceDateTime ? bookingData?.serviceDateTime : 'NA',
            status: bookingData?.bookingStatus,
            assignedAgentName: bookingData?.assignedAgentName || 'NA',
            vendorName: bookingData?.vendorName || 'NA',
            originalData: bookingData,
          };
        }),
      );
    } else {
      setBookingsData([]);
      setColumns([]);
    }
  }, [data, selectedBooking, isMultiSelectable]);

  return (
    <CustomizedTable
      columns={columns ?? []}
      data={bookingsData ?? []}
      totalCount={totalCount}
      limit={limit}
      handleRowsPerPageChange={handleRowsPerPageChange}
      currentPage={skip}
      handlePageChange={handlePageChange}
      indexType={Constant.INDEX_TYPE.ZERO_BASED}
      isTableDisplay={isTableDisplay}
      selectableRows={isMultiSelectable ? true : false}
      clearSelectedRows={shouldHaveClearSelection}
      onSelectedRowsChange={handleRowSelected}
    />
  );
};

export default TabularView;
